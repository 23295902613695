import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button, Tooltip } from 'reactstrap';
import RouteLabel from '../components/RouteLabel';
import Table from '../components/Table';
import axios from 'axios';
import { BASE_URL, getUserData, logout, downloadFile } from '../utils';
import LoadingBar from 'react-top-loading-bar';
import { File, Plus } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';
import LoadingComponent from '../components/LoadingComponent';
import ErrorComponent from '../components/ErrorComponent';
import Select from 'react-select';
import CommentsModal from '../components/CommentsModal';
import NotFound from '../components/NotFound';
import { async } from 'q';
import { PendingInvoices } from './PendingInvoices';
import { AcceptedInvoices } from './AcceptedInvoices';
import { blob } from '../dummy-db/blobData';
import { Base64 } from 'js-base64';
import TableTabs from '../components/TableTabs';
import ModalAlert from '../components/ModalAlert';
import { useDispatch, useSelector } from 'react-redux';
import { handleClearInput } from '../redux/actions/Table';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import TableDetailsModal from '../components/TableDetailsModal';
import InvoiceDetailsModal from '../components/InvoiceDetailsModal';


const InvoiceList = () => {

    const [t,i18n] = useTranslation("all-invoices");

    const ref = useRef(null);
    const navigate = useNavigate();
    const [invoiceList, setInvoiceList] = useState([]);
    const [retry, setRetry] = useState(false)
    const [openModal, setOpenModal] = useState(false);
    const [currentData, setCurrentData] = useState(null)
    const [err, setErr] = useState(false);
    const userData = getUserData();
    const [loading, setLoading] = useState(false)
    const [invoiceTbl, setInvoiceTbl] = useState("sent")
    const [currentRemarks, setCurrentRemarks] = useState([]);
    const [openAlert, setOpenAlert] = useState(false)
    const [currentInvoice, setCurrentInvoice] = useState(false);
    const [openDetailsModal,setOpenDetailsModal] = useState(false);

    // const [currentPurchaseOrder,setCurrentPurchaseOrder] = useState(false)
    const [mobileColumn,setMobileColumn] = useState(false)
    const dispatch = useDispatch();
    const login = getUserData();
    const curLang = useSelector(state=>state.langReducer)


    const notifySuccess = (msg)=>toast.success(msg)
    const notifyFailed = (msg)=>toast.error(msg)

    useEffect(()=>{
        i18n.changeLanguage(curLang);
       },[curLang])



    async function getAllInvoices() {
        if (ref.current)
            ref.current.continuousStart()
        setLoading(true);
        try {
            var res = await axios.get(BASE_URL + '/invoices?status='+invoiceTbl, {
                headers: {
                    "Content-Type": "application/json",
                    "mode": "cors",
                    "Authorization": login.accessToken
                }
            });
            if (res.status == 200 && res.data.invoices) {

                setInvoiceList(res.data.invoices);
                setErr(false)
            }
        }
        catch (e) {
            setErr(true);
            logout();
            localStorage.setItem("loginDetails", null)
            window.location.reload()

        }
        if (ref.current)
            ref.current.complete()
        setLoading(false)
    }



    useEffect(() => {
        if (err)
            setErr(!err)
        getAllInvoices();
    }, [retry,invoiceTbl])



    useEffect(()=>{

    
        if(window.innerWidth<=500)
          setMobileColumn([
            {
                Header: t('table.0'),
                accessor: "invoiceNumber"
            },
            {
              Header: t('table.8'),//"Action ",
              Cell: (row) => {
                return (
                  <div className='tbl-action-col'>
      
      
                      {/* <RenderBtn id={"edit"+row.row.id} tooltip={"Edit"} className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-edit" ></i>} onClick={() => { navigate("/partners/edit-partner", { state: { userData: { ...row.row.original } } }); }}></RenderBtn> */}
                      {userData.role=="ADMIN" && <RenderBtn id={"del" + row.row.id} tooltip={"Delete"} className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-trash" ></i>} onClick={() => { /*navigate("/session-timed-out");*/setCurrentInvoice(row.row.original); setOpenAlert(true); }}></RenderBtn>}
                            {row.row.original.status != "COMPLETED" ? <RenderBtn id={"add-rem" + row.row.id} tooltip={"Add Remarks"} className='tbl-action-btn add-remarks-btn' color="btn btn-primary" icon={<i className="fas fa-comments" ></i>} onClick={() => { setCurrentData(row.row.original);/*navigate("/session-timed-out");*/setOpenModal(true); getRemarks(row.row.original); }}></RenderBtn> : null}
                            <RenderBtn id={"download" + row.row.id} tooltip={"Download"} className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-arrow-down" ></i>} onClick={() => { downloadInvoice(row.row.original) }}></RenderBtn>
                            <RenderBtn id={"view"+row.row.id} tooltip={"View Details"} className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-ellipsis-v" ></i>} onClick={() => { setCurrentInvoice(row.row.original); setOpenDetailsModal(true); /* deletePurchaseOrder(row.row.original) /*navigate("/session-timed-out");*/ }}></RenderBtn>
                    
                  </div>
                );
              }
            },
            {
                Header: t('table.1'),
                accessor: "invoiceDate",
                hidden:true,
            },
            // {
            //     Header: "Start Date",
            //     accessor: "startDate"
            // },
            // {
            //     Header: "End Date",
            //     accessor: "endDate"
            // },
            // {
            //     Header: "Rate",
            //     accessor: "rate"
            // },
            // {
            //     Header: "Quantity",
            //     accessor: "quantity"
            // },
            {
                Header: t('table.2'),
                accessor: "amount",
                hidden:true,
            },
            // {
            //     Header: "VAT",
            //     accessor: "vat"
            // },
            {
                Header: t('table.3'),
                accessor: "totalAmount",
                hidden:true,
            },
            {
                Header: t('table.4'),
                accessor: "timesheet",
                hidden:true,
            },

            {
                Header: t('table.5'),
                accessor: "document",
                hidden:true,

            },
            {
                Header: t('table.6'),
                accessor: "email",
                hidden:true,

            },
            {
                Header: t('table.7'),
                accessor: "status",
                hidden:true,
                
            },
          ]
          )
  
  
     var winEvent = window.addEventListener('resize', function(event) { 
        const currentScreenWidth = event.target.innerWidth;
        if(currentScreenWidth<=500){
          setMobileColumn([
            {
                Header: t('table.0'),
                accessor: "invoiceNumber"
            },
            {
              Header: t('table.8'),//"Action ",
              Cell: (row) => {
                return (
                  <div className='tbl-action-col'>
      
      
                      {/* <RenderBtn id={"edit"+row.row.id} tooltip={"Edit"} className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-edit" ></i>} onClick={() => { navigate("/partners/edit-partner", { state: { userData: { ...row.row.original } } }); }}></RenderBtn> */}
                      {userData.role=="ADMIN" && <RenderBtn id={"del" + row.row.id} tooltip={"Delete"} className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-trash" ></i>} onClick={() => { /*navigate("/session-timed-out");*/setCurrentInvoice(row.row.original); setOpenAlert(true); }}></RenderBtn>}
                            {row.row.original.status != "COMPLETED" ? <RenderBtn id={"add-rem" + row.row.id} tooltip={"Add Remarks"} className='tbl-action-btn add-remarks-btn' color="btn btn-primary" icon={<i className="fas fa-comments" ></i>} onClick={() => { setCurrentData(row.row.original);/*navigate("/session-timed-out");*/setOpenModal(true); getRemarks(row.row.original); }}></RenderBtn> : null}
                            <RenderBtn id={"download" + row.row.id} tooltip={"Download"} className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-arrow-down" ></i>} onClick={() => { downloadInvoice(row.row.original) }}></RenderBtn>
                            <RenderBtn id={"view"+row.row.id} tooltip={"View Details"} className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-ellipsis-v" ></i>} onClick={() => { setCurrentInvoice(row.row.original); setOpenDetailsModal(true);/*setOpenAlert(true); /* deletePurchaseOrder(row.row.original) /*navigate("/session-timed-out");*/ }}></RenderBtn>
                    
                  </div>
                );
              }
            },
            {
                Header: t('table.1'),
                accessor: "invoiceDate",
                hidden:true,
            },
            // {
            //     Header: "Start Date",
            //     accessor: "startDate"
            // },
            // {
            //     Header: "End Date",
            //     accessor: "endDate"
            // },
            // {
            //     Header: "Rate",
            //     accessor: "rate"
            // },
            // {
            //     Header: "Quantity",
            //     accessor: "quantity"
            // },
            {
                Header: t('table.2'),
                accessor: "amount",
                hidden:true,
            },
            // {
            //     Header: "VAT",
            //     accessor: "vat"
            // },
            {
                Header: t('table.3'),
                accessor: "totalAmount",
                hidden:true,
            },
            {
                Header: t('table.4'),
                accessor: "timesheet",
                hidden:true,
            },

            {
                Header: t('table.5'),
                accessor: "document",
                hidden:true,

            },
            {
                Header: t('table.6'),
                accessor: "email",
                hidden:true,

            },
            {
                Header: t('table.7'),
                accessor: "status",
                hidden:true,
                
            },
          ]
          )
        }else{
          setMobileColumn(false)
        }
       })
  
      return ()=>window.removeEventListener('resize',winEvent)
  
    },[])

    const columns = /*useMemo( () =>*/
         [

            // {
            //   Header: "P.No",
            //   accessor: "btwNumber"

            // },
            // {
            //   Header: "Name",
            //   accessor: "name",

            // },
            {
                Header: t('table.0'),
                accessor: "invoiceNumber"
            },
            {
                Header: t('table.1'),
                accessor: "invoiceDate"
            },
            // {
            //     Header: "Start Date",
            //     accessor: "startDate"
            // },
            // {
            //     Header: "End Date",
            //     accessor: "endDate"
            // },
            // {
            //     Header: "Rate",
            //     accessor: "rate"
            // },
            // {
            //     Header: "Quantity",
            //     accessor: "quantity"
            // },
            {
                Header: t('table.2'),
                accessor: "amount"
            },
            // {
            //     Header: "VAT",
            //     accessor: "vat"
            // },
            {
                Header: t('table.3'),
                accessor: "totalAmount"
            },
            {
                Header: t('table.4'),
                accessor: "timesheet",
                Cell: (row) => <div onClick={() => downloadFile(row.row.original.timesheet)} ><div className="tbl-action-btn-dwn" ><i className='bi bi-arrow-down-square-fill' style={{ marginRight: "10px" }} />{row.row.original.timesheet} </div></div>
            },

            {
                Header: t('table.5'),
                accessor: "document",
                Cell: (row) => <div onClick={() => downloadFile(row.row.original.document)}><div className="tbl-action-btn-dwn" ><i className='bi bi-arrow-down-square-fill' style={{ marginRight: "10px" }} />{row.row.original.document}</div></div>

            },
            {
                Header: t('table.6'),
                accessor: "email",
                Cell: (row) => <div onClick={() => downloadFile(row.row.original.email)}><div className="tbl-action-btn-dwn" ><i className='bi bi-arrow-down-square-fill' style={{ marginRight: "10px" }} />{row.row.original.email}</div></div>

            },
            {
                Header: t('table.7'),
                accessor: "status",
                Cell: (row) => {
                    // alert(((row.row.original.status!="COMPLETED") + ',' + userData + ','+  (userData == "ADMIN")))
                    return (
                        <div className='tbl-action-col status-drpdwn' id={'status-drpdwn-' + row.row.id}>

                            {(row.row.original.status != "COMPLETED" && userData && userData.role == "ADMIN") ? <Select  menuPosition="fixed" menuPlacement="auto" className='form-drpdwn1'

                                options={[
                                    {
                                        label: "CREATED",
                                        value: "CREATED"

                                    }
                                    ,
                                    {
                                        label: "COMPLETED",
                                        value: "COMPLETED"
                                    },
                                    {
                                        label: "PENDING",
                                        value: "PENDING"
                                    },
                                    {
                                        label: "VOID",
                                        value: "VOID"
                                    },
                                    {
                                        label: "ACCEPTED",
                                        value: "ACCEPTED"
                                    }
                                ]}
                                onChange={(opt) => {
                                    changeStatus(opt.value, row.row.original)
                                }}
                                // value={{
                                //     label:row.row.original.status
                                // }}
                                placeholder={row.row.original.status}

                            /> : <div>{row.row.original.status}</div>}
                        </div>
                    );
                }
            },

            {
                Header: t('table.8'),
                Cell: (row) => {

                    return (
                        <div className='tbl-action-col'>

                            {/* <RenderBtn id={"edit"+row.row.id} tooltip={"Edit"} className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-edit" ></i>} onClick={() => { navigate("/partners/edit-partner", { state: { userData: { ...row.row.original } } }); }}></RenderBtn> */}
                            {userData.role=="ADMIN" && <RenderBtn id={"del" + row.row.id} tooltip={"Delete"} className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-trash" ></i>} onClick={() => { /*navigate("/session-timed-out");*/setCurrentInvoice(row.row.original); setOpenAlert(true); }}></RenderBtn>}
                            {row.row.original.status != "COMPLETED" ? <RenderBtn id={"add-rem" + row.row.id} tooltip={"Add Remarks"} className='tbl-action-btn add-remarks-btn' color="btn btn-primary" icon={<i className="fas fa-comments" ></i>} onClick={() => { setCurrentData(row.row.original);/*navigate("/session-timed-out");*/setOpenModal(true); getRemarks(row.row.original); }}></RenderBtn> : null}
                            <RenderBtn id={"download" + row.row.id} tooltip={"Download"} className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-arrow-down" ></i>} onClick={() => { downloadInvoice(row.row.original) }}></RenderBtn>

                        </div>
                    );
                }
            }

        ]
       /* ,
        []
    );*/


    function RenderBtn({ className, onClick, icon, tooltip, id, children, text }) {
        const [tooltipOpen, setTooltipOpen] = useState(false);
        const toggle = () => setTooltipOpen(!tooltipOpen);
        try {
            return (
                <>
                    <Button id={id} className={className} color="btn btn-primary" onClick={onClick}><span className='download-btn-filename'>{text}</span>  {icon} </Button>
                    <Tooltip
                        isOpen={tooltipOpen}
                        toggle={toggle}
                        placement="right" target={id} /*toggle={this.toggle}*/>
                        {tooltip}
                    </Tooltip>
                </>
            )
        }
        catch (e) {
            return (
                <>
                    <Button id={id} className={className} color="btn btn-primary" onClick={onClick}>{icon}</Button>

                </>
            )
        }
    }


    async function deleteInvoice(invData) {
        try {
            var res = await axios.delete(BASE_URL + '/invoices/' + invData.id, {
                headers: {
                    "Content-Type": "application/json",
                    "mode": "cors",
                    "Authorization": login.accessToken
                }
            });
            notifySuccess("Deleted Successfully")
            dispatch(handleClearInput(''));

            //if (res.status == 201 ) {

            //setInvoiceList("Remarks added"); 
            //}
        }
        catch (e) {
            //setErr(true);
            notifyFailed("Failed while deleting invoice")
        }
    }


    async function downloadInvoice(invData) {
        if (ref.current)
            ref.current.continuousStart()

        //BASE_URL + '/invoices/files/' + invData.invoiceNumber+'.pdf'
        axios({
            url: BASE_URL + '/invoices/files/' + invData.invoiceNumber + '.pdf',
            method: 'GET',
            responseType: 'blob',

            headers: {
                "Content-Type": "application/json",
                "mode": "cors",
                "Authorization": login.accessToken
            }

        }).then((response) => {

            const href = URL.createObjectURL(response.data);

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', invData.invoiceNumber + '_file.pdf');
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }).catch(
            function (error) {
                notifyFailed("Failed while Downloading file...")
                return;
            }
        )
        // try {
        //   var res = await axios.get(, {
        //     headers: {
        //       "Content-Type": "application/json",
        //       "Access-Control-Allow-Origin": "*"
        //     }
        //   });
        //   if (res.status == 200) {

        //     //setInvoiceList("Remarks added"); 
        //   }
        // }
        // catch (e) {
        //   //setErr(true);
        //   alert("Failed while Download...")
        // }
        if (ref.current)
            ref.current.complete()
    }


    async function downloadFile(file) {
        if (ref.current)
            ref.current.continuousStart()

        axios({
            url: BASE_URL + '/invoices/files/' + file + '.pdf',
            method: 'GET',
            responseType: 'blob',

            headers: {
                "Content-Type": "application/json",
                "mode": "cors",
                "Authorization": login.accessToken
            }

        }).then((response) => {

            const href = URL.createObjectURL(response.data);

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', file + '_file.pdf');
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }).catch(
            function (error) {
                notifyFailed("Failed while Downloading file...")
                return;
            }
        )
        if (ref.current)
            ref.current.complete()
    }



    async function changeStatus(status, invData) {
        if (ref.current)
            ref.current.continuousStart()
        try {
            var res = await axios.put(BASE_URL + '/invoices/' + invData.id, { status },{
                headers: {
                  "Content-Type": "application/json",
                  "mode": "cors",
                  "Authorization": login.accessToken
                }
              , params: {
                    "status": status
                }
            });
            notifySuccess("Updated")
            //if (res.status == 201 ) {

            //setInvoiceList("Remarks added"); 
            //}
        }
        catch (e) {
            //setErr(true);
            notifyFailed("Failed while Changing status...")
        }
        if (ref.current)
            ref.current.complete()
    }










    async function addRemarks(msg) {
        if (ref.current)
            ref.current.continuousStart()
        try {
            var res = await axios.post(BASE_URL + '/invoices/' + currentData.id + "/remarks", { remarks: msg },{
                headers: {
                  "Content-Type": "application/json",
                  "mode": "cors",
                  "Authorization": login.accessToken
                }
              });
            if (res.status == 200) {

                // alert("Remarks added");
                getRemarks(currentData);

            }
        }
        catch (e) {
            //setErr(true);
            notifyFailed("Failed while adding Remarks...")
        }
        if (ref.current)
            ref.current.complete()
    }


    async function getRemarks(remarkData) {

        if (ref.current)
            ref.current.continuousStart()
        try {
            var res = await axios.get(BASE_URL + '/invoices/' + remarkData.id + "/remarks",{
                headers: {
                  "Content-Type": "application/json",
                  "mode": "cors",
                  "Authorization": login.accessToken
                }
              });
            if (res.status == 200) {
                setCurrentRemarks(res.data)
                // alert("Remarks added");
            }
        }
        catch (e) {
            //setErr(true);
            notifyFailed("Failed while adding Remarks...")
        }
        if (ref.current)
            ref.current.complete()
    }

    function renderRemarkBtn() {


        return (
            <>
                <RenderBtn className='tbl-action-btn' color="btn btn-primary" onClick={() => { /*navigate("/session-timed-out");*/setOpenModal(true) }}><i className="fas fa-comments" ></i></RenderBtn>
            </>
        );
    }


    return (
        <div>
            {(invoiceTbl == "all" || invoiceTbl=="sent") ? <div>
                <LoadingBar color='#3072bb' ref={ref} height={7} />
                {invoiceList.length > 0 ? <div>
                    {/* <RouteLabel /> */}
                    <TableTabs tabsData={[
                        {
                            key: "sent",
                            label: "Sent Invoices",
                            icon: () => <i className="fas fa-paper-plane"></i>

                        },
                        {
                            key: "all",
                            label: "All Invoices",
                            icon: () => <i className="fas fa-list-ul"></i>
                        },
                        {
                            key: "pending",
                            label: "Pending Invoices",
                            icon: () => <i className="fas fa-file-invoice"></i>

                        },
                        {
                            key: "accepted",
                            label: "Accepted Invoices",
                            icon: () => <i className="fas fa-file"></i>

                        }
                    ]} setTab={setInvoiceTbl} currentTab={invoiceTbl}
                    />
                    <h2 className='page-title'>{t("header")}

                        {/* <div className='add-invoice-link' to='/invoices/create-invoice/' ><Button className='page-btn' onClick={() => setInvoiceTbl("all")}  >All Invoices <i className="bi bi-file-earmark-fill"></i></Button><Button className='page-btn' onClick={() => setInvoiceTbl("pending")}  >Pending Invoices <i className="bi bi-file-earmark-arrow-up-fill"></i></Button><Button className='page-btn' onClick={() => setInvoiceTbl("accepted")}  >Accepted Invoices <i className="bi bi-file-check-fill"></i></Button></div> */}
                    </h2>
                    <Table data={invoiceList} columns={mobileColumn?mobileColumn:columns} title={t("header")} />

                </div> : err ? <ErrorComponent retry={retry} setRetry={setRetry} /> : loading ? <LoadingComponent /> : <NotFound msg={"No Invoices found"} />}
                <CommentsModal isOpen={openModal} setIsOpen={setOpenModal} addRemarks={addRemarks} currentRemarks={currentRemarks} />
                <ModalAlert open={openAlert} setOpen={setOpenAlert} onSuccess={() => { if (currentInvoice) { deleteInvoice(currentInvoice); setCurrentInvoice(false) } }}>Are you sure to delete this Invoice</ModalAlert>
                <InvoiceDetailsModal isOpen={openDetailsModal} setIsOpen={setOpenDetailsModal} data={currentInvoice} downloadFile={downloadFile} changeStatus={changeStatus} />

            </div> : invoiceTbl == "pending" ? <PendingInvoices invoiceData={invoiceList} setInvoiceTbl={setInvoiceTbl} invoiceTbl={invoiceTbl} /> : <AcceptedInvoices invoiceData={invoiceList} setInvoiceTbl={setInvoiceTbl} invoiceTbl={invoiceTbl} />}
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>



    )
}

export { InvoiceList }