import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Alert, Button, Input, Label } from 'reactstrap'
import { BASE_URL, getUserData, validateZipCode } from '../utils';
import LoadingBar from 'react-top-loading-bar';
import PartnerCreateForm from '../components/PartnerCreateForm';
import { object, string, number, date, InferType, phone } from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalAlert from '../components/ModalAlert';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ConsultantForm from '../components/ConsultantForm';

const CreateConsultant = () => {

    const notifySuccess = () => toast.success("Consultant Created Successfully");
    const notifyErr = () => toast.error("Failed to create consultant!");
    const navigate = useNavigate();
    const [successMsg, setSuccessMsg] = useState(false)
    const login = getUserData();




    const [t, i18n] = useTranslation("create-consultant");




    const curLang = useSelector(state => state.langReducer)


    useEffect(() => {
        i18n.changeLanguage(curLang);
    }, [curLang])





    const ref = useRef(null);

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            location: '',
            employmentType: 'Permanent',
            startDate: '',
            endDate: '',
            rate: '',
            rateType: ''
        },
        validate: (values) => {

            const errors = {};

            if (!values.name || values.name.length <= 0) {
                errors.name = 'This field is Required';
            }


            if (!values.phone) {
                errors.phone = 'This field is Required';
            }

            else if (!(/^\d{10}$/i).test(values.phone)) {
                // alert("not")
                errors.phone = 'Invalid Phone Number';

            }


            if (!values.employmentType) {
                errors.employmentType = 'This field is Required';
            }

            if (values.employmentType == "Permanent") {
                if (!values.startDate) {
                    errors.startDate = 'This field is Required';
                }
                if (!values.endDate) {
                    errors.endDate = 'This field is Required';
                }
            }

            if (!values.rate) {
                errors.rate = 'This field is Required';
            }
            if (!values.rateType) {
                errors.rateType = 'This field is Required';
            }

            if (!values.email) {
                errors.email = ' This field is Required ';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                errors.email = 'Invalid email address';
            }

            if (!values.location) {
                errors.location = 'This field is required'
            }

            return errors;


        },
        onSubmit: (values) => {
            // alert();
            addConsultant();
        },
    });


    async function addConsultant() {

        try {
            if (ref.current)
                ref.current.continuousStart();
            let data = {};
            if (formik)
                data = { ...formik.values };
            data.phoneNumber = data.phone;

            if(data.employmentType=="Contract"){
                data.startDate = null;
                data.endDate = null;
            }




            var res = await axios.post(BASE_URL + '/consultants', { ...data }, {
                headers: {
                    "Content-Type": "application/json",
                    "mode": "cors",
                    "Authorization": login.accessToken
                }
            });
            if (res.status == 201) {

                setSuccessMsg(true);


            }
        } catch (e) {
            notifyErr();
        }
        if (ref.current)
            ref.current.complete()
    }



    return (
        <div className='create-partner-form-main'>
            <LoadingBar color='#3072bb' ref={ref} height={7} />

            <ModalAlert open={successMsg} setOpen={setSuccessMsg} msgType={"Success"} onSuccess={() => navigate('/consultants/consultants-list')} action="Ok" color="success">
                Consultant created Successfully!
            </ModalAlert>

            <div className='create-partner-form'>
                <div className='form-heading-label'>
                    <h1><i className="fas fa-user-plus" style={{ marginRight: "20px", }}></i>{t('header')}</h1>
                    <Link to="/consultants/consultants-list"> <Button className="page-btn">{t('right-nav-btn')}<i className='fas fa-eye'></i></Button> </Link>

                </div>
                <div className='form-body-row'>


                    <ConsultantForm formik={formik} />
                </div>
                <div className='form-bottom'>
                    <Button className={"page-btn " + (Object.keys(formik.errors).length > 0 ? 'disabled-btn' : '')} disabled={Object.keys(formik.errors).length > 0} onClick={formik.handleSubmit}>Save</Button>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export { CreateConsultant }