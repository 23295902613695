import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Input, Label } from 'reactstrap'
import { BASE_URL, getUserData, logout } from '../utils';
import LoadingBar from 'react-top-loading-bar';
import PartnerCreateForm from '../components/PartnerCreateForm';
import CreatePurchaseForm from '../components/CreatePurchaseForm';
import { object, string, number, date, InferType, phone } from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import ModalAlert from '../components/ModalAlert';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import LoadingComponent from '../components/LoadingComponent';

const EditPurchaseOrder = () => {


    const navigate = useNavigate();

    //const notifySuccess = () => toast.success("Edited Successfully");
    const notifyErr = () => toast.error("Failed to edit purchase order!");
    const [successMsg, setSuccessMsg] = useState(false)
    const [preLoading, setPreLoading] = useState(false);
    const login = getUserData();
    const [consultantsList, setConsultantsList] = useState([]);

    const [t, i18n] = useTranslation("create-purchase-order");




    const curLang = useSelector(state => state.langReducer)


    useEffect(() => {
        i18n.changeLanguage(curLang);
    }, [curLang])



    useEffect(() => {
        setPreLoading(true);
        getConsultants();
    }, [])






    async function getConsultants() {


        try {
            var res = await axios.get(BASE_URL + '/consultants', {
                headers: {
                    "Content-Type": "application/json",
                    "mode": "cors",
                    "Authorization": login.accessToken
                }
            });
            if (res.status == 200 && res.data.consultants) {

                res.data.consultants = res.data.consultants.map(val => {
                    var obj = { ...val };


                    obj.phone = obj.phoneNumber
                    val = obj
                    return val
                })
                setConsultantsList(res.data.consultants);
                // setErr(false)
            }
        }
        catch (e) {

            logout();

            localStorage.setItem("loginDetails", null)
            window.location.reload()

        }


        setPreLoading(false)
    }


    var location = useLocation(), orderData = {
        partnerId: '',
        consultantName: '',
        startDate: '2011-01-01',
        endDate: '2020-01-01',
        location: '',
        rate: '',
        type: '',
        currency: '',
        btwPercentage: '',
        paymentTerm: '',
        projectType: '',
        description: ''


    };
    if (location.state && location.state.orderData) {
        orderData = location.state.orderData;
    }
    else {
        navigate('/');
    }


    // else{
    //     // setTimeout(()=>navigate('/partners/partners-list'),1000)

    // }



    const ref = useRef(null);

    const formik = useFormik({
        initialValues: {
            partnerId: (orderData.partner ? orderData.partner.partnerId : ''),
            consultantName: orderData.consultantName,
            startDate: new Date(orderData.startDate),
            endDate: new Date(orderData.endDate),
            location: orderData.location,
            rate: orderData.rate,
            type: orderData.orderType,
            currency: orderData.currency,
            btwPercentage: orderData.btwPercentage.toString(),
            paymentTerm: orderData.paymentTerm,
            projectType: orderData.type,//projectType,
            description: orderData.description,



        },


        validate: (values) => {

            const errors = {};

            // if (!values.partnerId) {
            //     errors.partnerId = 'This field is Required';
            // }
            console.log(values.rate)

            if (!values.consultantName || values.consultantName.length < 0) {
                errors.consultantName = 'This field is Required';
            }

            if (!values.startDate) {
                errors.startDate = 'This field is Required';
            }

            if (!values.endDate) {
                errors.endDate = 'This field is Required';
            }

            if (!values.location) {
                errors.location = 'This field is Required';
            }

            if (!values.rate) {
                errors.rate = 'This field is Required';
            }
            else if (!values.rate.toString().match(/^\d*$/)) {
                errors.rate = 'Invalid Rate';
            }


            // if (!values.type) {
            //     errors.type = 'This field is Required';
            // }
            // if (!values.rate || !values.rate.match(/^\d*$/)) {
            //     errors.rate = 'Invalid Rate';
            // }

            if (!values.type && values.projectType != "FP") {
                console.log(values.projectType)
                errors.type = 'This field is Required';
            }

            if (!values.currency) {
                errors.currency = 'This field is Required';
            }

            // if(!values.projectType){
            //     errors.projectType = 'This field is Required';

            // }

            if (!values.btwPercentage || !values.btwPercentage.match(/^\d*$/)) {
                errors.btwPercentage = 'This field is Required';
            }

            if (!values.paymentTerm) {
                errors.paymentTerm = 'This field is Required';
            }

            return errors;


        },
        onSubmit: values => {
            editPurchaseOrder();
        },
    });


    useEffect(() => {
        if (orderData.partner == undefined)
            navigate('/')
    }, [])


    async function editPurchaseOrder() {

        try {
            if (ref.current)
                ref.current.continuousStart();
            let data = {};
            if (formik)
                data = { ...formik.values };
            data.orderType = data.type;

            data.type = data.projectType;


            data.startDate = data.startDate.getFullYear() + "-" + (data.startDate.getMonth() < 9 ? "0" + (data.startDate.getMonth() + 1) : (data.startDate.getMonth() + 1)) + "-" + (data.startDate.getDate() < 10 ? "0" + data.startDate.getDate() : data.startDate.getDate());
            data.endDate = data.endDate.getFullYear() + "-" + (data.endDate.getMonth() < 9 ? "0" + (data.endDate.getMonth() + 1) : (data.endDate.getMonth() + 1)) + "-" + (data.endDate.getDate() < 10 ? "0" + data.endDate.getDate() : data.endDate.getDate());

            // console.log(data,BASE_URL + '/purchaseorders/'+orderData.purchaseOrderNumber, { ...data }, {
            //     headers: {
            //         "Content-Type": "application/json",
            //         "Access-Control-Allow-Origin": "*"
            //     }
            // })
            var res = await axios.put(BASE_URL + '/purchaseorders/' + orderData.purchaseOrderNumber, { ...data }, {
                headers: {
                    "Content-Type": "application/json",
                    "mode": "cors",
                    "Authorization": login.accessToken
                }
            });
            if (res.status == 200) {

                // notifySuccess();
                setSuccessMsg(true);

                //setTimeout(() => navigate('/'), 10000)

            }
        } catch (e) {
            notifyErr();
        }
        if (ref.current)
            ref.current.complete()
    }




    return (
        <div className='create-partner-form-main'>
            <LoadingBar color='#3072bb' ref={ref} height={7} />
            <ModalAlert open={successMsg} setOpen={setSuccessMsg} msgType={"Success"} onSuccess={() => { if (orderData.partner.type == "Client") { navigate('/purchaseorders?partnerType=CLIENT') } else { navigate('/purchaseorders?partnerType=PARTNER') } }} action="Ok" color="success">
                Purchase order updated Successfully!
            </ModalAlert>
            {preLoading ? <LoadingComponent /> : <div className='create-partner-form'>
                <div className='form-heading-label'>
                    <h1><i className="fas fa-edit" style={{ marginRight: "20px", }}></i>{t('headerEdit')}  <span className='client-lbl'><i className='fas fa-user' />{t('client-title-header')}: <span className='client-lbl-value'>({orderData.partner.name})</span></span></h1>
                    <Link to={"/purchaseorders?partnerType=" + ((orderData.partner.type == "Client" ? "CLIENT" : "PARTNER"))}> <Button className="page-btn">{t('right-nav-btn')}  <i className='fas fa-eye'></i></Button> </Link>

                </div>
                <div className='form-body-row'>
                    <CreatePurchaseForm formik={formik} type={"edit"} consultantsList={consultantsList} />
                </div>
                <div className='form-bottom'>
                    <Button className={"page-btn " + (Object.keys(formik.errors).length > 0 ? 'disabled-btn' : '')} disabled={Object.keys(formik.errors).length > 0} onClick={formik.handleSubmit}>Update </Button>
                </div>
            </div>}
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export { EditPurchaseOrder }