import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import langPurchaseEN from './translations/EN/purchase-order/lang.json'
import langInvEN from './translations/EN/invoices/all/lang.json'
import langAccInvEN from './translations/EN/invoices/accepted/lang.json'
import langPendInvEN from './translations/EN/invoices/pending/lang.json'
import langPartnersEN from './translations/EN/partners/lang.json'
import langConsultantsEN from './translations/EN/consultants/lang.json'

import langClientsEN from './translations/EN/clients/lang.json'
import langCreateInvoiceEN from './translations/EN/invoices/createInvoice/lang.json'
import langCreatePurchaseOrderEN from './translations/EN/purchase-order/create-purchase-order/lang.json'
import langCreatePartnerEN from './translations/EN/partners/create-partner/lang.json'
import langCreateConsultantEN from './translations/EN/consultants/create-consultant/lang.json'
import langCreateClientEN from './translations/EN/clients/create-partner/lang.json'


import langPurchaseNL from './translations/NL/purchase-order/lang.json'
import langInvNL from './translations/NL/invoices/all/lang.json'
import langAccInvNL from './translations/NL/invoices/accepted/lang.json'
import langPendInvNL from './translations/NL/invoices/pending/lang.json'
import langPartnersNL from './translations/NL/partners/lang.json'
import langConsultantsNL from './translations/NL/consultants/lang.json'
import langClientsNL from './translations/NL/clients/lang.json'
import langCreateInvoiceNL from './translations/NL/invoices/createInvoice/lang.json'
import langCreatePurchaseOrderNL from './translations/NL/purchase-order/create-purchase-order/lang.json'
import langCreatePartnerNL from './translations/NL/partners/create-partner/lang.json'
import langCreateConsultantNL from './translations/NL/consultants/create-consultant/lang.json'
import langCreateClientNL from './translations/NL/clients/create-partner/lang.json'


// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  EN: {
    "purchase-order": langPurchaseEN,
    "all-invoices": langInvEN,
    "accepted-invoices": langAccInvEN,
    "pending-invoices": langPendInvEN,
    "partners":langPartnersEN,
    "consultants":langConsultantsEN,
    "clients":langClientsEN,
    "create-invoice":langCreateInvoiceEN,
    "create-purchase-order":langCreatePurchaseOrderEN,
    "create-partner":langCreatePartnerEN,
    "create-consultant":langCreateConsultantEN,
    "create-client":langCreateClientEN

  },
  NL: {
    "purchase-order": langPurchaseNL,
    "all-invoices": langInvNL,
    "accepted-invoices": langAccInvNL,
    "pending-invoices": langPendInvNL,
    "partners":langPartnersNL,
    "consultants":langConsultantsNL,
    "clients":langClientsNL,
    "create-invoice":langCreateInvoiceNL,
    "create-purchase-order":langCreatePurchaseOrderNL,
    "create-partner":langCreatePartnerNL,
    "create-consultant":langCreateConsultantNL,
    "create-client":langCreateClientNL

  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "EN", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;