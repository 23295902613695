import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Input, Label } from 'reactstrap'
import Select from 'react-select'
import { countryLabels } from '../utils';
import './css/PartnerForm.css';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import InvProductsListTbl from './InvProductsListTbl';

const GenerateInvoiceForm = ({ formik }) => {


    const [t, i18n] = useTranslation("create-partner");

    const [showTax, setShowTax] = useState(true);
    const [showDiscount, setShowDiscount] = useState(false);
    const [showShipping, setShowShipping] = useState(false);

    const [curTaxType, setCurTaxType] = useState("percentage");
    const [curDiscountType, setCurDiscountType] = useState("percentage");
    const [curShippingType, setCurShippingType] = useState("percentage");


    const curLang = useSelector(state => state.langReducer)


    useEffect(() => {
        i18n.changeLanguage(curLang);
    }, [curLang])



    function validateFormCustom() {
        // formik.touched.name = true;
        // formik.touched.phone = true;
        // formik.touched.alias = true;
        // formik.touched.contactPerson = true;
        // formik.touched.btwNumber = true;
        // formik.touched.email = true
        // formik.touched.houseNumber = true;
        // formik.touched.postalCode = true;
        // formik.touched.streetName = true;
        // formik.touched.country = true;
        formik.setFieldTouched("name", true)
        formik.setFieldTouched("phone", true)
        formik.setFieldTouched("alias", true)
        formik.setFieldTouched("contactPerson", true)
        formik.setFieldTouched("btwNumber", true)
        formik.setFieldTouched("email", true)
        formik.setFieldTouched("houseNumber", true)
        formik.setFieldTouched("postalCode", true)
        formik.setFieldTouched("streetName", true)
        formik.setFieldTouched("country", true)


    }

    function BillingClientsLabel() {
        return (
            <div className='billing-client-label'>
                <div className='partner-details'>
                    <span className='partner-name'>Sarthak Madhwal</span>
                    <div><span className='partner-number'>+91 99597238282</span>,<span className='partner-email'>madhwalsarthak@email.com</span></div>
                </div>
                <div className='btwNumber'>
                    <div className='tag'>A09X930Q</div>
                </div>
            </div>
        );
    }

    // if (formik)
    return (
        <div className='form-body inv-genform'>
            <div className='form-horizontal'>
                {/* <h3 className='form-grp-lbl'>Generate Invoice:</h3> */}
                <div className='inv-genform-heading'>
                    <label for="inv-logo" className='add-logo-btn'>
                        Add your logo  <i class="bi bi-plus-lg"></i>
                    </label>
                    <input type='file' id="inv-logo" style={{ display: "none" }} />
                    <div>
                        <Label className='heading-lbl'>{"INVOICE"}:</Label><br />
                        <div className="cp-name">
                            <i class="fas fa-hashtag"></i>
                            <Input type="text" placeholder={"Invoice label"} /><br />
                        </div>
                        {/* {formik.errors.name && formik.touched.name ? <span className='form-err-text'>{formik.errors.name}</span> : null} */}

                    </div>


                </div>
                <div className='mid-row1'>
                    <div className='col1'>
                        <div className='company-details'>
                            <Label><b>Callisto web solutions ,</b></Label>
                            <Label>Amstelveen </Label>
                        </div>

                        <div className='billing-details'>
                            <div className="cp-name">
                                <i class="fas fa-truck-moving"></i>
                                {/* <Input type="text" placeholder={"Bill to"} /><br /> */}
                                <Select
                                    //  menuPosition="fixed" menuPlacement="auto"  
                                    id="billing-client-drpdwn" className='form-drpdwn' options={[
                                        {
                                            label: <BillingClientsLabel />,
                                            value: "DAILY"
                                        },
                                        {
                                            label: <BillingClientsLabel />,
                                            value: "MONTHLY"
                                        },


                                    ]}
                                    placeholder={"Billing Client..."} />
                                <br />
                            </div>
                            <div className="cp-name">
                                <i class="fas fa-truck-moving"></i>
                                <Input type="text" placeholder={"Ship to"} /><br />
                            </div>
                        </div>
                    </div>


                    <div className='col2'>


                        <div className="cp-name">
                            <i class="bi bi-calendar-plus-fill"></i>
                            {/* <Input type="text" placeholder={"Date"} /><br /> */}
                            <DatePicker id="startdate" className='date-input' dateFormat="yyyy-MM-dd" placeholder="Enter Date..." selected={new Date()} /><br />
                        </div>
                        <div className="cp-name">
                            <i class="bi bi-credit-card"></i>
                            <Input type="text" placeholder={"Payment Terms"} /><br />
                        </div>

                        <div className="cp-name">
                            <i class="bi bi-calendar-check-fill"></i>
                            {/* <Input type="text" placeholder={"Due Date"} /><br /> */}
                            <DatePicker id="startdate" className='date-input' dateFormat="yyyy-MM-dd" placeholder="Due Date" selected={new Date().getTime() + (15 * 86400 * 1000)} /><br />

                        </div>

                        <div className="cp-name">
                            <i class="bi bi-file-earmark-diff-fill"></i>
                            <Input type="text" placeholder={"PO Number"} /><br />
                        </div>
                    </div>

                    {/* <div>
                            <Label>{t('form.0.labels.3')}:</Label><br />
                            <div className="cp-contact">
                                <i className='fas fa-users'></i>
                                <Input type="text" placeholder={t('form.0.labels.3')} name='contactPerson' value={formik.values.contactPerson} onChange={formik.handleChange} onBlur={(e) => { formik.handleBlur(e); validateFormCustom() }} /><br />

                            </div>
                            {formik.errors.contactPerson && formik.touched.contactPerson ? <span className='form-err-text'>{formik.errors.contactPerson}</span> : null}

                        </div> */}

                    {/* <div>
    <Label>Name:</Label><br/>
    <Input type="text" placeholder="Name.." />
</div> */}


                </div>


                <InvProductsListTbl formik={formik} />




                <div className='mid-row2'>
                    <div className='col1'>


                        <div className='notes-terms-details'>
                            <div className="cp-name">
                                <i class="fas fa-edit"></i>
                                <Input type="text" placeholder={"Notes - ny relevant information not covered"} /><br />
                            </div>
                            <div className="cp-name">
                                <i class="fas fa-file-contract"></i>
                                <Input type="text" placeholder={"Terms and Conditions"} /><br />
                            </div>
                        </div>
                    </div>

                    <div className='col2'>
                        <div className='billing-overview-row'>
                            <span>Subtotal</span><span>$0.00</span>
                        </div>
                        {showTax && <div className='billing-overview-row'>
                            <span>Tax</span>
                            <button className='tax-btn'>
                                <span className='value'>0</span>
                                <span className='preview'><i class={curTaxType != "percentage" ? "fas fa-euro-sign" : "fas fa-percentage"}></i></span>
                                {/* <span className='value'>0</span> */}
                                <span className='switchbtn' onClick={() => { if (curTaxType == "percentage") { setCurTaxType("") } else setCurTaxType("percentage") }}><i class="fas fa-retweet"></i></span>
                            </button>
                            <i className='fas fa-times' onClick={() => setShowTax(false)}></i>


                        </div>}
                        {showDiscount && <div className='billing-overview-row'>
                            <span>Discount</span>
                            <button className='tax-btn'>
                                <span className='value'>0</span>
                                <span className='preview'><i class={curDiscountType != "percentage" ? "fas fa-euro-sign" : "fas fa-percentage"}></i></span>
                                {/* <span className='value'>0</span> */}
                                <span className='switchbtn' onClick={() => { if (curDiscountType == "percentage") { setCurDiscountType("") } else setCurDiscountType("percentage") }}><i class="fas fa-retweet"></i></span>
                            </button>
                            <i className='fas fa-times' onClick={() => setShowDiscount(false)}></i>

                        </div>}
                        {showShipping && <div className='billing-overview-row'>
                            <span>Shipping</span>
                            <button className='tax-btn'>
                                <span className='value'>0</span>
                                <span className='preview'><i class={curShippingType != "percentage" ? "fas fa-euro-sign" : "fas fa-percentage"}></i></span>
                                {/* <span className='value'>0</span> */}
                                <span className='switchbtn' onClick={() => { if (curShippingType == "percentage") { setCurShippingType("") } else setCurShippingType("percentage") }}><i class="fas fa-retweet"></i></span>
                            </button>
                            <i className='fas fa-times' onClick={() => setShowShipping(false)}></i>


                        </div>}

                        <div className='add-tax-btn-sect'>
                            {!showTax && <span onClick={() => setShowTax(true)}>+ Tax</span>}
                            {!showDiscount && <span onClick={() => setShowDiscount(true)}>+ Discount</span>}
                            {!showShipping && <span onClick={() => setShowShipping(true)}>+ Shipping</span>}
                        </div>
                        <div className='billing-overview-row'>

                            <span>Total</span><span>$0.00</span>
                        </div>
                        <div className='billing-overview-row'>

                            <span>Amount Paid</span>
                            <button className='amt-btn'>
                                <span className='preview'><i class="fas fa-euro-sign"></i></span>
                                <span className='value'>0</span>
                                <span className='switchbtn'></span>
                            </button>
                        </div>
                        <div className='billing-overview-row'>

                            <span>Balance Due</span><span>$0.00</span>
                        </div>
                    </div>




                    {/* <div>
                            <Label>{t('form.0.labels.3')}:</Label><br />
                            <div className="cp-contact">
                                <i className='fas fa-users'></i>
                                <Input type="text" placeholder={t('form.0.labels.3')} name='contactPerson' value={formik.values.contactPerson} onChange={formik.handleChange} onBlur={(e) => { formik.handleBlur(e); validateFormCustom() }} /><br />

                            </div>
                            {formik.errors.contactPerson && formik.touched.contactPerson ? <span className='form-err-text'>{formik.errors.contactPerson}</span> : null}

                        </div> */}

                    {/* <div>
    <Label>Name:</Label><br/>
    <Input type="text" placeholder="Name.." />
</div> */}


                </div>

            </div>



        </div>
    )
    // else return null;
}

export default GenerateInvoiceForm