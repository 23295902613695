import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Input, Label } from 'reactstrap'
import { countryLabels } from '../utils';
import DatePicker from "react-datepicker";
import Select from 'react-select';

import "react-datepicker/dist/react-datepicker.css";
import './css/PartnerForm.css';

const ConsultantForm = ({ formik }) => {


    const [t, i18n] = useTranslation("create-consultant");




    const curLang = useSelector(state => state.langReducer)


    useEffect(() => {
        i18n.changeLanguage(curLang);
    }, [curLang])



    function validateFormCustom() {

        formik.setFieldTouched("name", true)
        formik.setFieldTouched("phone", true)
        formik.setFieldTouched("location", true)
        formik.setFieldTouched("employmentType", true)
        formik.setFieldTouched("rate", true)
        formik.setFieldTouched("email", true)
        formik.setFieldTouched("rateType", true)
        formik.setFieldTouched("startDate", true)
        formik.setFieldTouched("endDate", true)
        // formik.setFieldTouched("country", true)


    }


    if (formik) {
        var maxDate = new Date(formik.values.endDate)
        maxDate.setDate(maxDate.getDate() - 1);
        var minDate = new Date(formik.values.startDate)
        minDate.setDate(minDate.getDate() + 1);

        if(new Date(minDate).getTime()<0){
            minDate = new Date('1972-01-01');
        }

        return (
            <div className='form-body consultant-form'>
                {/* <div className='form-horizontal'> */}
                {/* <h3 className='form-grp-lbl'>{t('form.0.header')}:</h3> */}
                <div className='inp-rows'>
                    <div>
                        <Label>{t('form.0.labels.0')}:</Label><br />
                        <div className="cp-name">
                            <i className='fas fa-user'></i>
                            <Input type="text" placeholder={t('form.0.labels.0')} name='name' value={formik.values.name} onChange={formik.handleChange} onBlur={(e) => { formik.handleBlur(e); validateFormCustom() }} /><br />
                        </div>
                        {formik.errors.name && formik.touched.name ? <span className='form-err-text'>{formik.errors.name}</span> : null}

                    </div>

                    <div>
                        <Label>{t('form.0.labels.1')}:</Label><br />
                        <div className="cp-email">
                            <i className="fas fa-envelope"></i>
                            <Input type="email" placeholder={t('form.0.labels.1')} name='email' value={formik.values.email} onChange={formik.handleChange} onBlur={(e) => { formik.handleBlur(e); validateFormCustom() }} /><br />

                        </div>
                        {formik.errors.email && formik.touched.email ? <span className='form-err-text'>{formik.errors.email}</span> : null}

                    </div>


                </div>
                <div className='inp-rows'>
                    <div>
                        <Label>{t('form.0.labels.2')}:</Label><br />
                        <div className="cp-phone">
                            <i className='fas fa-phone'></i>
                            <Input type="text" placeholder={t('form.0.labels.2')} name='phone' value={formik.values.phone} onChange={formik.handleChange} onBlur={(e) => { formik.handleBlur(e); validateFormCustom() }} /><br />

                        </div>
                        {formik.errors.phone && formik.touched.phone ? <span className='form-err-text'>{formik.errors.phone}</span> : null}

                    </div>
                </div>
                <div className='inp-rows'>
                    <div>
                        <Label>{t('form.0.labels.3')}:</Label><br />
                        <div className="cp-email">
                            <i className='fas fa-map-pin'></i>
                            <Input type="text" placeholder={t('form.0.labels.3')} name='location' value={formik.values.location} onChange={formik.handleChange} onBlur={(e) => { formik.handleBlur(e); validateFormCustom() }} /><br />

                        </div>
                        {formik.errors.location && formik.touched.location ? <span className='form-err-text'>{formik.errors.location}</span> : null}

                    </div>
                    <div>
                        <Label>{t('form.0.labels.4')}</Label><br />
                        <div className="cp-id">
                            <i className='fas fa-user-tie'></i>

                            <Select menuPosition="fixed" menuPlacement="auto" id="employment-type-drpdwn" isDisabled={false} className='form-drpdwn' options={[
                                {
                                    label: "Permanent",
                                    value: "Permanent"
                                },
                                {
                                    label: "Contract",
                                    value: "Contract"
                                },



                            ]}
                                onBlur={(e) => { validateFormCustom(); formik.handleBlur(e) }}
                                onChange={(opt) => {
                                    formik.setFieldValue("employmentType", opt.value)
                                }}

                                placeholder={formik.values.employmentType == '' ? t('form.0.labels.4') : formik.values.employmentType} />
                        </div>
                        {formik.errors.employmentType && formik.touched.employmentType ? <span className='form-err-text'>{formik.errors.employmentType}</span> : null}

                    </div>

                </div>


                <div className='inp-rows cols-33'>
                    {formik.values.employmentType == "Permanent" && <div>
                        <Label>{t('form.0.labels.5')}:</Label><br />
                        <div className="cp-email">
                            <i className='fas fa-calendar-week'></i>
                            <DatePicker id="startdate" minDate={new Date('1972-01-01')} maxDate={maxDate} className='date-input' onBlur={(e) => { validateFormCustom(); formik.handleBlur(e) }} selected={formik.values.startDate} onChange={(date) => { formik.setFieldValue("startDate", date) }} dateFormat="yyyy-MM-dd" />

                        </div>
                        {formik.errors.startDate && formik.touched.startDate ? <span className='form-err-text'>{formik.errors.startDate}</span> : null}

                    </div>}

                    {formik.values.employmentType == "Permanent" && <div>
                        <Label>{t('form.0.labels.6')}:</Label><br />
                        <div className="cp-email">

                            <i className='fas fa-calendar-week'></i>
                            <DatePicker id="enddate" minDate={minDate} className='date-input' onBlur={(e) => { validateFormCustom(); formik.handleBlur(e) }} selected={formik.values.endDate} onChange={(date) => formik.setFieldValue("endDate", date)} dateFormat="yyyy-MM-dd" />

                        </div>
                        {formik.errors.endDate && formik.touched.endDate ? <span className='form-err-text'>{formik.errors.endDate}</span> : null}

                    </div>}


                    <div>
                        <Label>{t('form.0.labels.7')}:</Label><br />
                        <div className="cp-email">
                            <i className='fas fa-chart-line'></i>
                            <Input type="text" placeholder={t('form.0.labels.7')} name='rate' value={formik.values.rate} onChange={formik.handleChange} onBlur={(e) => { formik.handleBlur(e); validateFormCustom() }} /><br />

                        </div>
                        {formik.errors.rate && formik.touched.rate ? <span className='form-err-text'>{formik.errors.rate}</span> : null}

                    </div>
                </div>
                {/* </div> */}

                {/* <div className='form-horizontal'> */}



                <div className='inp-rows '>









                </div>


                <div className='inp-rows'>

                    <div>
                        <Label>{t('form.0.labels.8')}:</Label><br />
                        <div className="cp-email">
                            <i className="fas fa-chart-line"></i>

                            <Select menuPosition="fixed" menuPlacement="auto" id="rate-type-drpdwn" isDisabled={false} className='form-drpdwn' options={[
                                {
                                    label: "HOURLY",
                                    value: "HOURLY"
                                },
                                {
                                    label: "DAILY",
                                    value: "DAILY"
                                },



                            ]}
                                onBlur={(e) => { validateFormCustom(); formik.handleBlur(e) }}
                                onChange={(opt) => {
                                    formik.setFieldValue("rateType", opt.value)
                                }}

                                placeholder={formik.values.rateType == '' ? t('form.0.labels.8') : formik.values.rateType} />


                        </div>
                        {formik.errors.rateType && formik.touched.rateType ? <span className='form-err-text'>{formik.errors.rateType}</span> : null}

                    </div>








                </div>









            </div>
        )
    }
    else return null;
}

export default ConsultantForm