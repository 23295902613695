import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button, Tooltip } from 'reactstrap';
import RouteLabel from '../components/RouteLabel';
import Table from '../components/Table';
import axios from 'axios';
import { BASE_URL, getUserData, logout } from '../utils';
import LoadingBar from 'react-top-loading-bar';
import { Plus } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';
import LoadingComponent from '../components/LoadingComponent';
import ErrorComponent from '../components/ErrorComponent';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalAlert from '../components/ModalAlert';
import { handleClearInput } from '../redux/actions/Table';
import { useDispatch, useSelector } from 'react-redux';
import NotFound from '../components/NotFound';
import { useTranslation } from 'react-i18next';
import TableDetailsModal from '../components/TableDetailsModal';

const ConsultantsList = () => {

  const notifyDel = () => toast.success("Deleted Successfully");
  const notifyErr = () => toast.error("Failed to delete consultant!");

  const ref = useRef(null);
  const navigate = useNavigate();
  const [consultantsList, setConsultantsList] = useState([]);
  const [err, setErr] = useState(false);
  const [retry, setRetry] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [currentConsultant, setCurrentConsultant] = useState(false);
  const [openDetailsModal,setOpenDetailsModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [mobileColumn, setMobileColumn] = useState(false)
  const [t, i18n] = useTranslation("consultants");




  const curLang = useSelector(state => state.langReducer)


  useEffect(() => {


    if (window.innerWidth <= 500)
      setMobileColumn([
        {
          Header: t('table.0'),
          accessor: "name",
  
        },
        {
          Header: t('table.9'),
          Cell: (row) => {
            return (
              <div className='tbl-action-col'>


                <RenderBtn id={"edit" + row.row.id} tooltip="Edit" className='tbl-action-btn' color="btn btn-primary" onClick={() => { navigate("/consultants/edit-consultant", { state: { userData: { ...row.row.original } } }); }}><i className="fas fa-edit" ></i></RenderBtn>

                <RenderBtn id={"consultant-delete" + row.row.id} tooltip="Delete" className='tbl-action-btn' color="btn btn-primary" onClick={() => { setCurrentConsultant(row.row.original); setOpenAlert(true) }}><i className="fas fa-trash" ></i></RenderBtn>
                <RenderBtn id={"view" + row.row.id} tooltip={"View Details"} className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-ellipsis-v" ></i>} onClick={() => { setCurrentConsultant(row.row.original);setOpenDetailsModal(true) }}><i className="fas fa-ellipsis-v" ></i></RenderBtn>

              </div>
            );
          }
        },
        {
          Header: t('table.1'),
          accessor: "email",
          hidden: true
        },
        {
          Header: t('table.2'),
          accessor: "phone",
          hidden: true
        },
        {
          Header: t('table.3'),
          accessor: "location",
          hidden: true
        },
        {
          Header: t('table.4'),
          accessor: "employmentType",
          hidden: true
        },
        {
          Header: t('table.5'),
          accessor: "startDate",
          Cell:(row)=><span>{row.row.original.startDate?row.row.original.startDate:"N/A"}</span>,
          hidden: true
        },
        {
          Header: t('table.6'),
          accessor: "endDate",
          Cell:(row)=><span>{row.row.original.endDate?row.row.original.endDate:"N/A"}</span>,
          hidden: true
        },
        {
          Header: t('table.7'),
          accessor: "rate",
          hidden: true
        },
        {
          Header: t('table.8'),
          accessor: "rateType",
          hidden: true
        },
      ]
      )


    var winEvent = window.addEventListener('resize', function (event) {
      const currentScreenWidth = event.target.innerWidth;
      if (currentScreenWidth <= 500) {
        setMobileColumn([
          {
            Header: t('table.0'),
            accessor: "name",
    
          },
          {
            Header: t('table.9'),
            Cell: (row) => {
              return (
                <div className='tbl-action-col'>


                  <RenderBtn id={"edit" + row.row.id} tooltip="Edit" className='tbl-action-btn' color="btn btn-primary" onClick={() => { navigate("/consultants/edit-consultant", { state: { userData: { ...row.row.original } } }); }}><i className="fas fa-edit" ></i></RenderBtn>

                  <RenderBtn id={"consultant-delete" + row.row.id} tooltip="Delete" className='tbl-action-btn' color="btn btn-primary" onClick={() => { setCurrentConsultant(row.row.original); setOpenAlert(true) }}><i className="fas fa-trash" ></i></RenderBtn>
                  <RenderBtn id={"view" + row.row.id} tooltip={"View Details"} className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-ellipsis-v" ></i>} onClick={() => { setCurrentConsultant(row.row.original);setOpenDetailsModal(true) }}><i className="fas fa-ellipsis-v" ></i></RenderBtn>

                </div>
              );
            }
          },

          {
            Header: t('table.1'),
            accessor: "email",
            hidden: true
          },
          {
            Header: t('table.2'),
            accessor: "phone",
            hidden: true
          },
          {
            Header: t('table.3'),
            accessor: "location",
            hidden: true
          },
          {
            Header: t('table.4'),
            accessor: "employmentType",
            hidden: true
          },
          {
            Header: t('table.5'),
            accessor: "startDate",
            Cell:(row)=><span>{row.row.original.startDate?row.row.original.startDate:"N/A"}</span>,
            hidden: true
          },
          {
            Header: t('table.6'),
            accessor: "endDate",
            Cell:(row)=><span>{row.row.original.endDate?row.row.original.endDate:"N/A"}</span>,
            hidden: true
          },
          {
            Header: t('table.7'),
            accessor: "rate",
            hidden: true
          },
          {
            Header: t('table.8'),
            accessor: "rateType",
            hidden: true
          },
        ]
        )
      } else {
        setMobileColumn(false)
      }
    })

    return () => window.removeEventListener('resize', winEvent)

  }, [])

  useEffect(() => {
    i18n.changeLanguage(curLang);
  }, [curLang])




  const login = getUserData();
  const dispatch = useDispatch()

  async function getConsultants() {
    if (ref.current)
      ref.current.continuousStart()
    setLoading(true)
    try {
      var res = await axios.get(BASE_URL + '/consultants', {
        headers: {
          "Content-Type": "application/json",
          "mode": "cors",
          "Authorization": login.accessToken
        }
      });
      if (res.status == 200 && res.data.consultants) {

        res.data.consultants = res.data.consultants.map(val => {
          var obj = { ...val };

          
          obj.phone = obj.phoneNumber
          val = obj
          return val
        })
        setConsultantsList(res.data.consultants);
        setErr(false)
      }
    }
    catch (e) {
      setErr(true);
      logout();

      localStorage.setItem("loginDetails", null)
      window.location.reload()

    }
    if (ref.current)
      ref.current.complete()
    setLoading(false)
  }


  useEffect(() => {
    if (err)
      setErr(!err)
    getConsultants();
  }, [retry])


  const columns = /*useMemo(
    () =>*/ [


        
      {
        Header: t('table.0'),
        accessor: "name",

      },
      {
        Header: t('table.1'),
        accessor: "email"
      },
      {
        Header: t('table.2'),
        accessor: "phone"
      },
      {
        Header: t('table.3'),
        accessor: "location"
      },
      {
        Header: t('table.4'),
        accessor: "employmentType"
      },
      {
        Header: t('table.5'),
        accessor: "startDate",
        Cell:(row)=><span>{row.row.original.startDate?row.row.original.startDate:"N/A"}</span>
      },
      {
        Header: t('table.6'),
        accessor: "endDate",
        Cell:(row)=><span>{row.row.original.endDate?row.row.original.endDate:"N/A"}</span>
      },
      {
        Header: t('table.7'),
        accessor: "rate"
      },
      {
        Header: t('table.8'),
        accessor: "rateType"
      },
      

      {
        Header: t('table.9'),
        Cell: (row) => {


          return (
            <div className='tbl-action-col'>

              <RenderBtn id={"edit" + row.row.id} tooltip="Edit" className='tbl-action-btn' color="btn btn-primary" onClick={() => { navigate("/consultants/edit-consultant", { state: { userData: { ...row.row.original } } }); }}><i className="fas fa-edit" ></i></RenderBtn>

              <RenderBtn id={"consultant-delete" + row.row.id} tooltip="Delete" className='tbl-action-btn' color="btn btn-primary" onClick={() => { setCurrentConsultant(row.row.original); setOpenAlert(true) /*deletePartner(row.row.original)*//*navigate("/session-timed-out");*/ }}><i className="fas fa-trash" ></i></RenderBtn>

            </div>
          );
        }
      }

    ]

    


  async function deleteConsultant(consultant) {
    if (ref.current)
      ref.current.continuousStart()
    try {
      var res = await axios.delete(BASE_URL + `/consultants/${consultant.consultantId}`, {
        headers: {
          "Content-Type": "application/json",
          "mode": "cors",
          "Authorization": login.accessToken
        }
      })
      if (res.status == 200 || res.status == 204) {
        notifyDel();
        getConsultants();
        dispatch(handleClearInput(''));
      }
    }
    catch (e) {
      notifyErr();
    }
    if (ref.current)
      ref.current.complete()
  }

  function RenderBtn({ className, onClick, icon, tooltip, id, children }) {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    return (
      <>

        <Button id={id} className={className} color="btn btn-primary" onClick={onClick}>{children}</Button>
        <Tooltip
          isOpen={tooltipOpen}
          toggle={toggle}
          placement="right" target={id} /*toggle={this.toggle}*/>
          {tooltip}
        </Tooltip>
      </>
    )
  }


  return (
    <div>
      <LoadingBar color='#3072bb' ref={ref} height={7} />
      {consultantsList && consultantsList.length > 0 ?  <div>
        <RouteLabel />
        <h2 className='page-title'>{t("header")}<Link className='add-invoice-link' to='/consultants/create-consultant/' ><Button className='page-btn'  >Create Consultant <Plus size={20} /></Button></Link></h2>
        <Table data={consultantsList} columns={mobileColumn?mobileColumn:columns} title={t("header")} />
      </div> : err ? <ErrorComponent retry={retry} setRetry={setRetry} /> : loading ? <LoadingComponent /> : <NotFound msg={'No consultants found!'} >
        <Link className='add-invoice-link' to='/consultants/create-consultant/' ><Button className='page-btn'  >Create Consultant <Plus size={20} /></Button></Link>
      </NotFound>}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ModalAlert open={openAlert} setOpen={setOpenAlert} onSuccess={() => { if (currentConsultant) { deleteConsultant(currentConsultant); setCurrentConsultant(false) } }}>Are you sure to delete this consultant</ModalAlert>
      <TableDetailsModal isOpen={openDetailsModal} setIsOpen={setOpenDetailsModal} data={currentConsultant}   />
    </div>
  )
}

export { ConsultantsList }