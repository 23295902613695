import axios from 'axios'
import React, { useState, useEffect, useMemo } from 'react'
import { Menu } from 'react-feather'
import DatePicker from "react-datepicker";
import Select from 'react-select';

import "react-datepicker/dist/react-datepicker.css"; import { Button, Col, Input, Label, Row } from 'reactstrap'
import { BASE_URL } from '../utils'
import Table from './Table'
import ModalAlert from './ModalAlert';
import { Link, useNavigate } from 'react-router-dom';

const InvoiceForm = ({ orderData, formik, setRateDetails, validateFile, translation }) => {


    const monthsList = [
  { label: "January", value: "01" },
  { label: "February", value: "02" },
  { label: "March", value: "03" },
  { label: "April", value: "04" },
  { label: "May", value: "05" },
  { label: "June", value: "06" },
  { label: "July", value: "07" },
  { label: "August", value: "08" },
  { label: "September", value: "09" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" }
      ];


    
    const [openConfirmModal, setOpenModal] = useState(false);
    const [currentInvDateOption,setCurrentInvDateOption] = useState("custom-date");
    const [rateType] = useState()
    const [yearsList,setYearsList] = useState([]);
    const [currentYear,setCurrentYear] = useState(new Date().getFullYear());
    const [currentMonth,setCurrentMonth] = useState({ label: "January", value: "01" });

    const navigate = useNavigate();
    // const [employees, setEmployees] = useState([]);

    // async function getEmployees() {
    //     try {
    //         var res = await axios.get(BASE_URL + '/invoice_purchase');
    //         if (res.status == 200) {
    //             setEmployees(res.data);
    //         }
    //     }
    //     catch (e) {

    //     }
    // }


    // useEffect(() => {
    //     getEmployees();
    // }, [])


    // const columns = useMemo(
    //     () => [
    //         {

    //             Header: "P.No",
    //             accessor: "p_no"
    //         },
    //         {
    //             Header: "Partner",
    //             accessor: "partner",

    //         },
    //         {
    //             Header: "Consultant",
    //             accessor: "consultant",

    //         },
    //         {
    //             Header: "Start Date",
    //             accessor: "start_date"
    //         },
    //         {
    //             Header: "End Date",
    //             accessor: "end_date"
    //         },
    //         {
    //             Header: "Location",
    //             accessor: "location"
    //         },
    //         {
    //             Header: "Rate",
    //             accessor: "rate"
    //         },
    //         {
    //             Header: "Type",
    //             accessor: "type"
    //         },
    //         {
    //             Header: "Currency",
    //             accessor: "currency"
    //         },
    //         {
    //             Header: "Vat Rate",
    //             accessor: "vat"
    //         },
    //         {
    //             Header: "Payment term",
    //             accessor: "payment_term"
    //         },
    //         {
    //             Header: "Routing",
    //             accessor: "routing"
    //         },

    //         {
    //             Header: "Action ",
    //             Cell: (row) => {

    //                 return (
    //                     <div style={{ display: "flex" }}>

    //                         <Button className='tbl-action-btn' color="btn btn-primary"><i className="fas fa-plus" style={{ margin: "0px 10px", cursor: "pointer", color: "#fff", fontSize: "10px" }}></i></Button>
    //                         <Button className='tbl-action-btn' color="btn btn-primary"><i className="fas fa-arrow-down" style={{ margin: "0px 10px", cursor: "pointer", color: "#fff", fontSize: "10px" }}></i></Button>
    //                     </div>
    //                 );
    //             }
    //         }
    //     ]
    //     ,
    //     []
    // );


    function validateFormCustom() {

        formik.setFieldTouched("invoiceNumber", true)
        formik.setFieldTouched("quantity", true)
        formik.setFieldTouched("notes", true)
        formik.setFieldTouched("document", true)
        formik.setFieldTouched("timesheet", true)
        formik.setFieldTouched("approvalEmail", true)
        formik.setFieldTouched("terms", true)



    }

    function changeDateFromDropdown(){

        

        let month = currentMonth.value;
        let startDay = "01",endDay = new Date(currentYear, parseInt(month), 0).getDate();

        

        formik.setFieldValue("startDate" , new Date(currentYear+"-"+month+"-"+startDay));
        formik.setFieldValue("endDate" , new Date(currentYear+"-"+month+"-"+endDay));

    }

    // console.log(formik)

    useEffect(() => {
        if (formik.values.type == "FP") {
            setRateDetails(1)
        }
        var currentYear = new Date().getFullYear();
        var tempArr=[];
        for(var i=currentYear;i>=currentYear-5;--i){
            tempArr.push({label:i,value:i})
        }
        setYearsList([...tempArr]);
    }, [])



    useEffect(()=>{        
        
        changeDateFromDropdown(currentMonth);

    },[currentMonth,currentYear])

    return (
        <div className='create-invoice-form-sect'>
            <div className='header'>
                <div className="tbl-title-header">
                    <h2><Menu size={20} /> {translation("header")}</h2>

                </div>
            </div>

            <form onSubmit={formik.handleSubmit}>
                <Row className='create-invoice-form'>
                    <Col md="6">
                        <Row>
                            <Col md="6">
                                <Label>{translation("formField.0")}</Label> </Col>
                            <Col md="6">
                                <Input disabled name="invoiceNumber" value={formik.values.invoiceNumber} onBlur={formik.handleBlur} /> <br />
                                {formik.errors.invoiceNumber && formik.touched.invoiceNumber && <span className='form-err-text'>This field is required</span>}
                            </Col>
                        </Row>
                        <Row>

                            <Col md="6">
                                <Label>{translation("formField.1")}</Label>
                            </Col>
                            <Col md="6">
                                {/* <Input disabled={false} value={formik.values.invoiceDate} onBlur={formik.handleBlur} /> <br /> */}

                                <DatePicker className='date-input' onBlur={formik.handleBlur} selected={formik.values.invoiceDate} onChange={(date) => { formik.setFieldValue("invoiceDate", date) }} dateFormat="yyyy-MM-dd" />

                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                                            <div className='inv-date-option-wrap'>
                                                                <div className='inv-option'>
                                                                    <Input checked={currentInvDateOption=="dropdown-date"}  onClick={()=>setCurrentInvDateOption("dropdown-date")} type='radio' id="date-dropdown"></Input>
                                                                    <Label for="date-dropdown">Month</Label>
                                                                </div>
                                                                <div className='inv-option'>
                                                                    <Input checked={currentInvDateOption=="custom-date"} onClick={()=>setCurrentInvDateOption("custom-date")} type='radio' id="date-calendar"></Input>
                                                                    <Label for="date-calendar">Custom Date</Label>
                                                                </div>
                                                            </div>
                                                        </Col>
                            <Col md="6">
                                <Label>{translation("formField.2")}</Label>
                            </Col>
                            <Col md="6">
                                 { currentInvDateOption=="custom-date" ?
                                <DatePicker className='date-input' onBlur={formik.handleBlur} selected={formik.values.startDate} onChange={(date) => { formik.setFieldValue("startDate", date) }} dateFormat="yyyy-MM-dd" />
                                : <div><DatePicker className='hidden' onBlur={formik.handleBlur} selected={formik.values.startDate} onChange={(date) => formik.setFieldValue("startDate", date)} dateFormat="yyyy-MM-dd" />

                                                                     <Select placeholder={currentYear?currentYear:"Select Year..."} options = {yearsList}  onChange={(opt)=>{setCurrentYear(opt.value)}} />
                                                                     {/* <Select placeholder="Select Year..." /> */}
                                                                  </div>

                                                                }
                            </Col>
                        </Row >
                        <Row>

                            <Col md="6">

                                <Label>{translation("formField.3")}</Label>
                            </Col>
                            <Col md="6">
                                { currentInvDateOption=="custom-date" ?
                                <DatePicker className='date-input' onBlur={formik.handleBlur} selected={formik.values.endDate} onChange={(date) => formik.setFieldValue("endDate", date)} dateFormat="yyyy-MM-dd" />
                                 : <div>
                                                                                                <DatePicker className="hidden" onBlur={formik.handleBlur} selected={formik.values.endDate} onChange={(date) => formik.setFieldValue("endDate", date)} dateFormat="yyyy-MM-dd" />

                                                                                                     <Select placeholder={currentMonth.value?currentMonth.label:"Select Month..."} options = {monthsList}  onChange={(opt)=>{setCurrentMonth(opt);}} />
                                                                                                     {/* <Select placeholder="Select Year..." /> */}
                                                                                                  </div>

                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">

                                <Label>{translation("formField.4")}{formik.values.rateType=='HOURLY'?'(Hours)':'(Days)'}</Label>

                            </Col>
                            <Col md="6">

                                <Input min={1} disabled={formik.values.type == "FP"} type="number" name="quantity" placeholder={formik.values.type == "FP" ? 1 : ''} onChange={e => setRateDetails(e.target.value)} onBlur={formik.handleBlur} /> <br />
                                {formik.errors.quantity && formik.touched.quantity && <span className='form-err-text'>This field is required</span>}

                            </Col>
                        </Row>
                        {/* {alert(orderData.type)} */}
                        {/*orderData.type!="FP"*/true ? <Row >

                            <Col md="6">

                                <Label>{translation("formField.5")}</Label>
                            </Col>

                            <Col md="6">

                                <Input disabled value={formik.values.rateType} onBlur={formik.handleBlur} /> <br />
                            </Col>
                        </Row> : null}
                        <Row>

                            <Col md="6">

                                <Label>{translation("formField.6")}</Label>
                            </Col>
                            <Col md="6">

                                <Input disabled value={formik.values.rate} onBlur={formik.handleBlur} /> <br />
                            </Col>
                        </Row>


                        {/* Notes */}

                        <Row>

                            <Col md="6">

                                <Label>{translation("formField.14")}</Label>
                            </Col>
                            <Col md="6">
                                <Input type='text' name="notes" rows={4} value={formik.values.notes} onChange={formik.handleChange} onBlur={formik.handleBlur} /> <br />
                                {formik.errors.notes && formik.touched.notes && <span className='form-err-text'>This field is required</span>}
                            </Col>
                        </Row>
                    </Col >






                    <Col md="6">
                        <Row>
                            <Col md="6">
                                <Label>{translation("formField.7")}</Label> </Col>
                            <Col md="6">
                                <Input disabled value={formik.values.amount} onBlur={formik.handleBlur} onChange={formik.handleChange} /> <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <Label>{translation("formField.8")}</Label>
                            </Col>
                            <Col md="6">
                                <Input disabled value={formik.values.btwAmount} onBlur={formik.handleBlur} onChange={formik.handleChange} /> <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <Label>{translation("formField.9")}</Label>
                            </Col>
                            <Col md="6">
                                <Input disabled value={formik.values.totalAmount} onBlur={formik.handleBlur} onChange={formik.handleChange} /> <br />
                            </Col>
                        </Row >
                        <Row>

                            <Col md="6">

                                <Label>{translation("formField.10")}</Label>
                            </Col>
                            <Col md="6">
                                {/* {console.log(formik.values.document)} */}
                                <Label className='custom-upload-btn' for='inv-docs'>{formik.values.document ? formik.values.document.name : "No file chosen..."}</Label>
                                <Input id="inv-docs" className='hidden' title={formik.values.document ? formik.values.document.name : "No file chosen..."} name="docs" accept="application/pdf" onChange={(event) => { if (validateFile(event.target, "document")) { formik.setFieldValue("document", event.target.files[0]) } }} type="file" onBlur={formik.handleBlur} /> <br />
                                {formik.errors.document && formik.touched.document && <span className='form-err-text'>This field is required</span>}

                            </Col>
                        </Row>
                        {formik.values.type != "FP" && <Row>
                            <Col md="6">

                                <Label>{translation("formField.11")}</Label>
                            </Col>
                            <Col md="6">
                                <Label className='custom-upload-btn' for='inv-timesheets'>{formik.values.timesheet ? formik.values.timesheet.name : "No file chosen..."}</Label>

                                <Input id="inv-timesheets" className='hidden' title={formik.values.timesheet ? formik.values.timesheet.name : "No file chosen..."} name="timesheets" accept="application/pdf" onChange={(event) => { if (validateFile(event.target, "timesheet")) { formik.setFieldValue("timesheet", event.target.files[0]) } }} type="file" onBlur={formik.handleBlur} /> <br />
                                {formik.errors.timesheet && formik.touched.timesheet && <span className='form-err-text'>This field is required</span>}

                            </Col>
                        </Row>}
                        {formik.values.type != "FP" && <Row>

                            <Col md="6">

                                <Label>{translation("formField.12")}</Label>
                            </Col>

                            <Col md="6">
                                <Label className='custom-upload-btn' for='inv-approvalEmail'>{formik.values.approvalEmail ? formik.values.approvalEmail.name : "No file chosen..."}</Label>
                                <Input id="inv-approvalEmail" className='hidden' title={formik.values.approvalEmail ? formik.values.approvalEmail.name : "No file chosen..."} name="approveEmail" accept="application/pdf" onChange={(event) => { if (validateFile(event.target, "approvalEmail")) { formik.setFieldValue("approvalEmail", event.target.files[0]) } }} type="file" onBlur={formik.handleBlur} /> <br />
                                {formik.errors.approvalEmail && formik.touched.approvalEmail && <span className='form-err-text'>This field is required</span>}

                            </Col>
                        </Row>}
                        <Row>

                            <Col md="6">

                                <Label>{translation("formField.13")}</Label>
                            </Col>
                            <Col md="6">

                                {/* <Input type='select'   onBlur={formik.handleBlur} /> <br /> */}
                                <Select menuPosition="fixed" menuPlacement="auto" options={[
                                    {
                                        label: "CREATED",
                                        value: "CREATED"
                                    },
                                    {
                                        label: "PENDING",
                                        value: "PENDING"
                                    }, {
                                        label: "ACCEPTED",
                                        value: "ACCEPTED"
                                    }
                                ]}

                                    onChange={(opt) => {
                                        formik.setFieldValue("status", opt.value)
                                    }}
                                    placeholder={formik.values.status}

                                />
                            </Col>
                        </Row>

                        <Row>

                            <Col md="6">

                                <Label>{translation("formField.15")}</Label>
                            </Col>
                            <Col md="6">
                                <Input type='textarea' rows={4} name="terms" value={formik.values.terms} onChange={formik.handleChange} onBlur={formik.handleBlur} /> <br />
                                {formik.errors.terms && formik.touched.terms && <span className='form-err-text'>This field is required</span>}

                            </Col>

                        </Row>

                    </Col>
                </Row >

                <div className='create-invoice-form-bottom'>
                    <Button color="btn btn-danger" onClick={() => setOpenModal(true)}>Cancel</Button>
                    <Button color="btn btn-primary" className={"page-btn " + (Object.keys(formik.errors).length > 0 ? 'disabled-btn' : '')} disabled={Object.keys(formik.errors).length > 0} onClick={formik.handleSubmit} type='submit'>Create Invoice</Button>

                    <ModalAlert children={"Do you want to cancel"} msgType="Are you sure" open={openConfirmModal} setOpen={setOpenModal} action={"Confirm"}
                        onSuccess={() => navigate('/')}
                    />

                </div>
            </form>
        </div >
    )
}

export default InvoiceForm