// import React, { useState } from 'react'
// import '../layout/layout.css'
// import './css/components.css'

// import { Link } from 'react-router-dom'
// import { ChevronDown, ChevronsDown, Clipboard, File, Menu, Users } from 'react-feather'
// import { useDispatch, useSelector } from 'react-redux'
// import { toggleSidebarGlobal } from '../redux/actions/sidebar'
// import DropdownLink from './DropdownLink'
// import logo from '../assets/callisto-logo.svg'
// import { getUserData } from '../utils'

// const Sidebar = () => {

//   const invoiceSubLinks = [ /*{ title: 'Accepted Invoices', path: '/invoices/accepted-invoice' },*//* { title: 'Pending Invoices', path: '/invoices/pending-invoice' },*/ { title: 'All Invoices', path: '/invoices/all-invoices' },{ title: 'Generate Invoice', path: '/invoices/generate-invoice', /*admin:true*/ }]
//   const partnerSubLinks = [/*{ title: 'Edit Partner', path: '/partners/edit-partner' },*/{ title: 'All Partners', path: '/partners/partners-list' }/*,{ title: 'Create Purchase Order', path: '/partners/create-purchase-order' }*/]
//   var userData = getUserData();

//   const dispatch = useDispatch();
//   var sidebarAction = useSelector(state => state.sidebarCollapseReducer)


  

//   return (
//     <div className='sidebar-col-inner' onClick={()=>{ if(sidebarAction)dispatch(toggleSidebarGlobal(!sidebarAction))}} >
//       <h5 className='sidebar-heading' >
      
      
//         <span ><img src={logo} width="120" height="27" /> </span>
//         <Menu size={27} id="sidebar-menu-btn" className='menu-toggle-icn' onClick={() => dispatch(toggleSidebarGlobal(!sidebarAction))} />
//       </h5>
//       <div className='navigation-container'>



//       {userData && userData.role=="ADMIN"? <Link to='/dashboard' id="dashboard">
//           <div className='side-nav-parent'>
//             <div className='side-nav'>
//             <i className="bi bi-bar-chart-fill" style={{ "color": "#484848", paddingRight: "20px" }}></i>  
//               {sidebarAction?<></>:<span className='sidebar-nav-title-main'>Dashboard</span>}
//             </div>

            
//             <div className='preview' style={!sidebarAction?{}:{display:"block"}}>
//             <i className="bi bi-bar-chart-fill" style={{ "color": "#484848", paddingRight: "20px" }}></i>  
//             </div>


//           </div>
//       </Link>:null}




//         <Link to='/' id="purchase-orders">
//           <div className='side-nav-parent'>
//             <div className='side-nav'>
//               <File size={36} style={{ "color": "#484848", paddingRight: "20px" }} />
//               {sidebarAction?<></>:<span className='sidebar-nav-title-main'>Partner Purchase Orders</span>}
//             </div>

            
//             <div className='preview' style={!sidebarAction?{}:{display:"block"}}>
//               <i className="fas fa-home" style={{ "color": "#484848", paddingRight: "20px" }}></i>
//             </div>


//           </div>
//         </Link>


//         <DropdownLink link={'/'} id="invoices" icon={'fas fa-file-invoice'} title={'Invoices'} sublinks={invoiceSubLinks} featherIcon={<Clipboard size={37} style={{ color: "#333", paddingRight: "20px" }} />}  isSidebarCollapsed={sidebarAction} />
//        {userData && userData.role=="ADMIN"? <DropdownLink id="partners" link={'/'} icon={'fas fa-file-invoice'} title={'Partners'} sublinks={partnerSubLinks} featherIcon={<Users size={37} style={{ color: "#333", paddingRight: "20px" }} />}  isSidebarCollapsed={sidebarAction} />:null}

//       </div>

//     </div>
//   )
// }

// export default Sidebar



import React, { useState } from 'react'
import '../layout/layout.css'
import './css/components.css'

import { Link } from 'react-router-dom'
import { ChevronDown, ChevronsDown, Clipboard, File, Menu, Users } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { toggleSidebarGlobal } from '../redux/actions/sidebar'
import DropdownLink from './DropdownLink'
import logo from '../assets/callisto-logo.svg'
import { getUserData } from '../utils'

const Sidebar = () => {

  const invoiceSubLinks = [ /*{ title: 'Accepted Invoices', path: '/invoices/accepted-invoice' },*//* { title: 'Pending Invoices', path: '/invoices/pending-invoice' },*/ { title: 'All Invoices', path: '/invoices/all-invoices' },/*{ title: 'Generate Invoice', path: '/invoices/generate-invoice', admin:true }*/]
  const partnerSubLinks = [/*{ title: 'Edit Partner', path: '/partners/edit-partner' },*/{ title: 'All Partners', path: '/partners/partners-list' },{ title: 'Partner Purchase Orders', path: '/purchaseorders?partnerType=PARTNER' }/*,{ title: 'Create Purchase Order', path: '/partners/create-purchase-order' }*/]
  const clientSubLinks = [/*{ title: 'Edit Partner', path: '/partners/edit-partner' },*/{ title: 'All Clients', path: '/clients/client-list' },{ title: 'Client Purchase Orders', path: '/purchaseorders?partnerType=CLIENT' }/*,{ title: 'Create Purchase Order', path: '/partners/create-purchase-order' }*/]
    var userData = getUserData();

  const dispatch = useDispatch();
  var sidebarAction = useSelector(state => state.sidebarCollapseReducer)


  

  return (
    <div className='sidebar-col-inner' onClick={()=>{ if(sidebarAction)dispatch(toggleSidebarGlobal(!sidebarAction))}} >
      <h5 className='sidebar-heading' >
      
      
        <span ><img src={logo} width="120" height="27" /> </span>
        <Menu size={27} id="sidebar-menu-btn" className='menu-toggle-icn' onClick={() => dispatch(toggleSidebarGlobal(!sidebarAction))} />
      </h5>
      <div className='navigation-container'>



      {userData && userData.role=="ADMIN"? <Link to='/dashboard' id="dashboard">
          <div className='side-nav-parent'>
            <div className='side-nav'>
            <i className="bi bi-bar-chart-fill" style={{ "color": "#484848", paddingRight: "20px" }}></i>  
              {sidebarAction?<></>:<span className='sidebar-nav-title-main'>Dashboard</span>}
            </div>

            
            <div className='preview' style={!sidebarAction?{}:{display:"block"}}>
            <i className="bi bi-bar-chart-fill" style={{ "color": "#484848", paddingRight: "20px" }}></i>  
            </div>


          </div>
      </Link>:null}

      {userData && userData.role=="ADMIN"? <Link to='/consultants/consultants-list' id="dashboard">
          <div className='side-nav-parent'>
            <div className='side-nav'>
            <i className="bi bi-person-fill-add" style={{ "color": "#484848", paddingRight: "20px",fontSize:"19px" }}></i>  
              {sidebarAction?<></>:<span className='sidebar-nav-title-main'>Consultants</span>}
            </div>

            
            <div className='preview' style={!sidebarAction?{}:{display:"block"}}>
            <i className="bi bi-person-fill-add" style={{ "color": "#484848", paddingRight: "20px",fontSize:"19px" }}></i>  
            </div>


          </div>
      </Link>:null}




      {userData && userData.role=="PARTNER"?<Link to='/purchaseorders?partnerType=PARTNER' id="purchase-orders">
          <div className='side-nav-parent'>
            <div className='side-nav'>
              <File size={36} style={{ "color": "#484848", paddingRight: "20px" }} />
              {sidebarAction?<></>:<span className='sidebar-nav-title-main'>Partner Purchase Orders</span>}
            </div>

            
            <div className='preview' style={!sidebarAction?{}:{display:"block"}}>
              <i className="fas fa-home" style={{ "color": "#484848", paddingRight: "20px" }}></i>
            </div>


          </div>
        </Link>:null}


        <DropdownLink link={'/'} id="invoices" icon={'fas fa-file-invoice'} title={'Invoices'} sublinks={invoiceSubLinks} featherIcon={<Clipboard size={37} style={{ color: "#333", paddingRight: "20px" }} />}  isSidebarCollapsed={sidebarAction} />
       {userData && userData.role=="ADMIN"? <DropdownLink id="partners" link={'/'} icon={/*'fas fa-file-invoice'*/'fas fa-users'} title={'Partners'} sublinks={partnerSubLinks} featherIcon={<Users size={37} style={{ color: "#333", paddingRight: "20px" }} />}  isSidebarCollapsed={sidebarAction} />:null}
       {userData && userData.role=="ADMIN"? <DropdownLink id="clients" link={'/'} icon={'bi bi-person-lines-fill'} title={'Clients'} sublinks={clientSubLinks} featherIcon={false && <Users size={37} style={{ color: "#333", paddingRight: "20px" }} />}  isSidebarCollapsed={sidebarAction} />:null}

      </div>

    </div>
  )
}

export default Sidebar