import axios from "axios";

export const BASE_URL = "https://api.zakelijkfacturen.nl/im-api/invoice-management"//"https://api.ras360.nl/im-api/invoice-management"
export const BASE_LOGIN_URL = "https://api.zakelijkfacturen.nl/im-api/invoice-management"
export const testingUrl =/*"https://ras360.nl"*/"http://localhost:3000"
//"http://localhost:8000" //"https://ras360.nl/im-api/invoice-management"

//http://185.107.90.252:8086/im-api/invoice-management

export const getUserData = () => {
  var login = localStorage.getItem("loginDetails");
  if (login) {
    login = JSON.parse(login);
  }
  else {
    login = {}
  }
  return login;

}





export const logout = async () => {

  const login = getUserData();
  var res = await axios.delete(BASE_URL + "/logout", {
    headers: {
      "Content-Type": "application/json",
      "mode": "cors",
      "Authorization": login.accessToken
    }
  });
  if (res.status == 200) {
    return true;
  }
  return false;
}




export function validateZipCode(zipCode) {
  const zipcodeChar = zipCode.split("");
  let nums = 0, letters = 0;
  zipcodeChar.map(char => {
    if (isNumber(char))
      nums++;
    else if (isLetter(char))
      letters++;


  })

  if (nums == 4 && letters == 2)
    return true;
  return false;
}

function isNumber(char) {
  return /^\d$/.test(char);
}



function isLetter(char) {
  return /^[a-zA-Z]$/.test(char);
}


export const countryLabels = [{
  label: 'Netherlands',
  value: 'Netherlands'
}]

export const allCountriesList = [
  "Afghanistan", "Albania", "Algeria", "Andorra", "Angola",
  "Antigua and Barbuda", "Argentina", "Armenia", "Australia", "Austria",
  "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados",
  "Belarus", "Belgium", "Belize", "Benin", "Bhutan",
  "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei",
  "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia",
  "Cameroon", "Canada", "Central African Republic", "Chad", "Chile",
  "China", "Colombia", "Comoros", "Congo, Democratic Republic of the", "Congo, Republic of the",
  "Costa Rica", "Croatia", "Cuba", "Cyprus", "Czech Republic",
  "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador",
  "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia",
  "Eswatini", "Ethiopia", "Fiji", "Finland", "France",
  "Gabon", "Gambia", "Georgia", "Germany", "Ghana",
  "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau",
  "Guyana", "Haiti", "Honduras", "Hungary", "Iceland",
  "India", "Indonesia", "Iran", "Iraq", "Ireland",
  "Israel", "Italy", "Jamaica", "Japan", "Jordan",
  "Kazakhstan", "Kenya", "Kiribati", "Korea, North", "Korea, South",
  "Kosovo", "Kuwait", "Kyrgyzstan", "Laos", "Latvia",
  "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein",
  "Lithuania", "Luxembourg", "Madagascar", "Malawi", "Malaysia",
  "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania",
  "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco",
  "Mongolia", "Montenegro", "Morocco", "Mozambique", "Myanmar",
  "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand",
  "Nicaragua", "Niger", "Nigeria", "North Macedonia", "Norway",
  "Oman", "Pakistan", "Palau", "Panama", "Papua New Guinea",
  "Paraguay", "Peru", "Philippines", "Poland", "Portugal",
  "Qatar", "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis",
  "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa", "San Marino", "Sao Tome and Principe",
  "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone",
  "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia",
  "South Africa", "South Sudan", "Spain", "Sri Lanka", "Sudan",
  "Suriname", "Sweden", "Switzerland", "Syria", "Taiwan",
  "Tajikistan", "Tanzania", "Thailand", "Timor-Leste", "Togo",
  "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan",
  "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom",
  "United States of America", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City",
  "Venezuela", "Vietnam", "Yemen", "Zambia", "Zimbabwe"
];


export const getValidDate = (dateStr) => {

 
    var date = new Date();
    console.log(date.getTime())
    return date.getFullYear() + "-" + (date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-" + (date.getDate() < 10 ? "0" + (date.getDate()) : date.getDate())



}

export const currencyLabels = [{
  label: 'EUR',
  value: 'EUR'
}, {
  label: 'DOLLAR',
  value: 'DOLLAR'
}, {
  label: 'INR',
  value: 'INR'
}, {
  label: 'Swedish Krona',
  value: 'Swedish Krona'
}]