import React, { useRef, useEffect, useState } from 'react'
import InvoiceForm from '../components/InvoiceForm'
import { useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik';
import { BASE_URL, getUserData, logout } from '../utils';
import axios from 'axios';
import LoadingBar from 'react-top-loading-bar';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import ModalAlert from '../components/ModalAlert';
import LoadingComponent from '../components/LoadingComponent';

export const CreateInvoice = () => {

  const login = getUserData();

  

  const notifySuccess = (msg) => toast.success(msg)
  const notifyFailed = (msg) => toast.error(msg)

  const navigate = useNavigate();
  var location = useLocation();
  var orderData = {
    invoiceNumber: '',
    invoiceDate: '',
    startDate: '',
    endDate: '',
    quantity: '',
    rate: '',
    rateType: '',
    amount: '',
    btwAmount: '',
    totalAmount: '',
    status: '',
    projectType: ''
    // notes:'',
    // terms:''


  };
  if (location.state && location.state.orderData) {
    orderData = location.state.orderData;
    // orderData.projectType="FE"
  }
  else {
    navigate('/');
  }

  console.log(orderData)

  const [t, i18n] = useTranslation("create-invoice");

  const curLang = useSelector(state => state.langReducer)


  useEffect(() => {
    i18n.changeLanguage(curLang);
  }, [curLang])



  async function getInvoiceNumber(){
    try{
      var res = await axios.get(BASE_URL+'/invoices/next-invoice-number?projectCode='+orderData.partner.alias,{
        headers: {
          "Content-Type": "application/json",
          "mode": "cors",
          "Authorization": login.accessToken
        }
      });
      if(res.status==200){
        // console.log(res.data)
        formik.setFieldValue("invoiceNumber",res.data);
      }

    }catch(e){
        formik.setFieldValue(undefined);
        logout();
    }
  }

  useEffect(()=>{
    getInvoiceNumber();
  },[])


  var ref = useRef(null);
  var currentDate = new Date();
  var { startDate, endDate } = orderData;

  const date1 = new Date(startDate);
  const date2 = new Date(endDate);
  const [successMsg, setSuccessMsg] = useState(false)
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  var amount = 0;
  const formik = useFormik({
    initialValues: {
      "purchaseOrderId": orderData.purchaseOrderNumber,
      invoiceNumber: '',
      invoiceDate: new Date(currentDate.getFullYear() + "-" + (currentDate.getMonth() < 9 ? ("0" + (currentDate.getMonth() + 1)) : (currentDate.getMonth() + 1)) + "-" + (currentDate.getDate() < 10 ? ("0" + currentDate.getDate()) : (currentDate.getDate()))),
      startDate: new Date(orderData.startDate),
      endDate: new Date(orderData.endDate),
      quantity: orderData.type == "FP" ? 1 : 0,
      rate: orderData.rate,
      rateType: orderData.orderType ? orderData.orderType : 'FIXED',
      amount: amount,
      projectType: orderData.type,//.projectType,
      type: orderData.type,
      "btwNumber": orderData.btwNumber,
      btwAmount: (orderData.btwPercentage * amount) / 100 || 0,
      totalAmount: amount + ((orderData.btwPercentage * amount) / 100) || 0,
      status: "CREATED",
      document: undefined,
      timesheet: undefined,
      approvalEmail: undefined,
      notes: '',
      terms: ''



    },
    validate: (values) => {

      const errors = {};

      if (!values.invoiceNumber) {
        errors.invoiceNumber = 'This field is Required';
      }

      if (!values.invoiceDate) {
        errors.invoiceDate = 'This field is Required';
      }

      if (!values.startDate) {
        errors.startDate = 'This field is Required';
      }

      if (!values.endDate) {
        errors.endDate = 'This field is Required';
      }

      if (!values.quantity) {
        errors.quantity = 'This field is Required';
      }

      if (!values.rate) {
        errors.rate = 'This field is Required';
      }

      if (!values.rateType) {
        errors.rateTypes = 'This field is Required';
      }

      if (!values.amount) {
        errors.amount = 'This field is Required';
      }

      if (!values.btwAmount && isNaN(values.btwAmount)) {
        errors.btwAmount = 'This field is Required';
      }

      if (!values.totalAmount) {
        errors.totalAmount = 'This field is Required';
      }

      // Extra validations for orderType == TM


      if (orderData.type == "TM") {

        if (!values.document) {
          errors.document = "This field is required"
        }

        if (!values.timesheet) {
          errors.timesheet = "This field is required"
        }

        if (!values.approvalEmail) {
          errors.approvalEmail = "This field is required"
        }

        if (!values.notes) {
          errors.notes = "This field is required"
        }

        if (!values.notes) {
          errors.notes = "This field is required"
        }
        if (!values.terms) {
          errors.terms = "This field is required"
        }

      }

      return errors;


    },
    onSubmit: values => {
      try {

        const url = window.URL.createObjectURL(
          new Blob([values.document]),
        );
        const reader = new FileReader();
        // const reader2 = new FileReader();
        // const reader3 = new FileReader();
        //console.log(values)
        var docs = {};
        reader.readAsDataURL(values.document);
        reader.onloadend = () => {
          try {

            formik.setFieldValue("document", reader.result)

            docs.document = reader.result
            reader.readAsDataURL(values.timesheet);


            reader.onloadend = () => {
              try {
                formik.setFieldValue("timesheet", reader.result)
                docs.timesheet = reader.result

                reader.readAsDataURL(values.approvalEmail);
                reader.onloadend = () => {
                  formik.setFieldValue("approvalEmail", reader.result)
                  // reader.readAsDataURL(values.timesheets);
                  docs.approvalEmail = reader.result

                  addInvoice(docs);

                };
              } catch (e) {
                if (orderData.type == "TM") {
                  notifyFailed("Error occur while loading files. Please try again");
                } else
                  addInvoice(docs);
              }



            };
          } catch (e) {
            if (orderData.type == "TM") {
              notifyFailed("Error occur while loading files. Please try again");
            } else
              addInvoice(docs);
          }

        };
      } catch (err) {
        if (orderData.type == "TM") {
          notifyFailed("Error occur while loading files. Please try again");
        } else
          addInvoice(docs);

      }

    },
  });


  console.log(formik)



  const addInvoice = async (docs) => {

    try {
      if (ref.current)
        ref.current.continuousStart();

      var data = { ...formik.values, ...docs };
      if (!data.document) {
        delete data.document
      }

      if (!data.timesheet) {
        delete data.timesheet
      }

      if (!data.approvalEmail) {
        delete data.approvalEmail
      }

      data.startDate = data.startDate.getFullYear() + "-" + (data.startDate.getMonth() < 9 ? ("0" + (data.startDate.getMonth() + 1)) : (data.startDate.getMonth() + 1)) + "-" + (data.startDate.getDate() < 10 ? "0" + data.startDate.getDate() : data.startDate.getDate());
      data.endDate = data.endDate.getFullYear() + "-" + (data.endDate.getMonth() < 9 ? ("0" + (data.endDate.getMonth() + 1)) : (data.endDate.getMonth() + 1)) + "-" + (data.endDate.getDate() < 10 ? "0" + data.endDate.getDate() : data.endDate.getDate());
      var url = BASE_URL + "/invoices";
      if (orderData.partnerType == "CLIENT") {
        url = url + '/client'
      }
      var res = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          "mode": "cors",
          "Authorization": login.accessToken
        }
      });
      if (res.status == 201) {
        // notifySuccess("Invoice Created Sucessfully");
        // navigate("/invoices/all-invoices")
        setSuccessMsg(true)
      }
    } catch (e) {
      notifyFailed("Failed")
      navigate("/")

    }
    if (ref.current)
      ref.current.complete();
  }


  function validateFile(input, field) {


    if (input.files == undefined) {
      notifyFailed("Please upload a PDF file.");
      input.value = "";
      input.placeholder = ""
      formik.setFieldValue(field, undefined)
      return false;
    }

    var file = input.files[0];
    if (file==undefined || file.type !== "application/pdf") {

      notifyFailed("Please upload a PDF file.");
      input.value = "";
      return false;
    }
    return true;
  }

  function setRateDetails(qty) {

    formik.setFieldValue("quantity", qty);
    //var amount = orderData.orderType == "HOURLY" ? orderData.rate * qty * 8 : orderData.rate * qty
    var amount = orderData.rate * qty
    formik.setFieldValue("amount", amount)
    formik.setFieldValue("btwAmount", (orderData.btwPercentage * amount) / 100 || 0)
    formik.setFieldValue("totalAmount", amount + ((orderData.btwPercentage * amount) / 100 || 0))

  }

 
  if(!formik.values.invoiceNumber){
    console.log(formik.values.invoiceNumber)
    return <LoadingComponent />
  }


  return (
    <div className='create-invoice-form-sect'>
      <LoadingBar color='#3072bb' ref={ref} height={7} />
      <h2 className='page-title'>{t("header")}</h2>

      <InvoiceForm translation={t} orderData={orderData} formik={formik} setRateDetails={setRateDetails} validateFile={validateFile} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ModalAlert open={successMsg} setOpen={setSuccessMsg} msgType={"Success"} onSuccess={() => navigate('/invoices/all-invoices')} action="Ok" color="success">
        Invoice created Successfully!
      </ModalAlert>
    </div>
  )
}

