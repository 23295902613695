import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Input, Label } from 'reactstrap'
import { BASE_URL, getUserData, logout } from '../utils';
import LoadingBar from 'react-top-loading-bar';
import PartnerCreateForm from '../components/PartnerCreateForm';
import CreatePurchaseForm from '../components/CreatePurchaseForm';
import { object, string, number, date, InferType, phone } from 'yup';
import ModalAlert from '../components/ModalAlert';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import LoadingComponent from '../components/LoadingComponent';

const CreatePurchaseOrder = () => {

    const notifySuccess = (msg) => toast.success(msg)
    const notifyFailed = (msg) => toast.error(msg)
    const navigate = useNavigate();
    const ref = useRef(null);
    const [successMsg, setSuccessMsg] = useState(false)
    const [preLoading, setPreLoading] = useState(false);
    const login = getUserData();
    const [consultantsList, setConsultantsList] = useState([]);



    const [t, i18n] = useTranslation("create-purchase-order");



    const curLang = useSelector(state => state.langReducer)


    useEffect(() => {
        setPreLoading(true);
        getConsultants();
    }, [])






    async function getConsultants() {
        
        
        try {
            var res = await axios.get(BASE_URL + '/consultants', {
                headers: {
                    "Content-Type": "application/json",
                    "mode": "cors",
                    "Authorization": login.accessToken
                }
            });
            if (res.status == 200 && res.data.consultants) {

                res.data.consultants = res.data.consultants.map(val => {
                    var obj = { ...val };


                    obj.phone = obj.phoneNumber
                    val = obj
                    return val
                })
                setConsultantsList(res.data.consultants);
                // setErr(false)
            }
        }
        catch (e) {

            logout();

            localStorage.setItem("loginDetails", null)
            window.location.reload()

        }

        
        setPreLoading(false)
    }








    useEffect(() => {
        i18n.changeLanguage(curLang);
    }, [curLang])

    var location = useLocation(), userData = {
        id: '',
        name: '',
        email: '',
        contactPerson: '',
        btwNumber: '',
        phone: ''
    };
    if (location.state && location.state.userData) {
        userData = location.state.userData;
    }
    else {

        navigate('/partners/partners-list')
    }



    console.log(userData)


    let purchaseOrderSchema = object({
        name: string().required(),
        age: number().required().positive().integer(),
        email: string().email(),
        website: string().url().nullable(),
        createdOn: date().default(() => new Date()),
    });


    const formik = useFormik({
        initialValues: {
            partnerId: userData.partnerId,
            consultantName: '',
            startDate: '',
            endDate: '',
            location: '',
            rate: '',
            type: '',
            currency: '',
            btwPercentage: '',
            paymentTerm: '',
            partner: { ...userData },
            projectType: '',
            description: '',
            consultantId:''


        },
        validate: (values) => {

            const errors = {};

            // if (!values.partnerId) {
            //     errors.partnerId = 'This field is Required';
            // }

            if (!values.consultantName || values.consultantName.length < 0) {
                errors.consultantName = 'This field is Required';
            }

            if (!values.startDate) {
                errors.startDate = 'This field is Required';
            }

            if (!values.endDate) {
                errors.endDate = 'This field is Required';
            }

            if (!values.location) {
                errors.location = 'This field is Required';
            }

            // if (!values.rate) {
            //     errors.rate = 'This field is Required';
            // }
            if (!values.rate || !values.rate.match(/^\d*$/)) {
                errors.rate = 'Invalid Rate';
            }

            if (!values.type && values.projectType != "FP") {
                console.log(values.projectType)
                errors.type = 'This field is Required';
            }

            if (!values.currency) {
                errors.currency = 'This field is Required';
            }

            // if(!values.projectType){
            //     errors.projectType = 'This field is Required';

            // }

            // if (!values.btwPercentage) {
            //     errors.btwPercentage = 'This field is Required';
            // }

            if (!values.btwPercentage || !values.btwPercentage.match(/^\d*$/)) {
                errors.btwPercentage = 'Invalid BTW Number';
            }

            if (!values.paymentTerm) {
                errors.paymentTerm = 'This field is Required';
            }

            if (!values.description) {
                errors.description = 'This field is Required';
            }


            return errors;


        },
        onSubmit: values => {
            addPurchaseOrder();
        },
    });


    async function addPurchaseOrder() {

        try {
            if (ref.current)
                ref.current.continuousStart();
            let data = {};
            if (formik)
                data = { ...formik.values, };
            data.orderType = data.type;

            data.type = data.projectType;

            data.startDate = data.startDate.getFullYear() + "-" + (data.startDate.getMonth() < 9 ? "0" + (data.startDate.getMonth() + 1) : (data.startDate.getMonth() + 1)) + "-" + (data.startDate.getDate() < 10 ? "0" + data.startDate.getDate() : data.startDate.getDate());
            data.endDate = data.endDate.getFullYear() + "-" + (data.endDate.getMonth() < 9 ? "0" + (data.endDate.getMonth() + 1) : (data.endDate.getMonth() + 1)) + "-" + (data.endDate.getDate() < 10 ? "0" + data.endDate.getDate() : data.endDate.getDate());
            // data.orderType=data.type;
            // data.partnerId = "238996ca-318e-416f-9ff6-860b8ffa5285"//userData.partnerId;
            var res = await axios.post(BASE_URL + '/purchaseorders', { ...data }, {
                headers: {
                    "Content-Type": "application/json",
                    "mode": "cors",
                    "Authorization": login.accessToken
                }
            });
            if (res.status == 201) {

                // alert("Created Successfully")
                setSuccessMsg(true);
                // navigate('/')
            }
        } catch (e) {
            notifyFailed("Failed")
        }
        if (ref.current)
            ref.current.complete()
    }

    

    
        

    return (
        <div className='create-partner-form-main'>
            <LoadingBar color='#3072bb' ref={ref} height={7} />

            {preLoading?<LoadingComponent />:<div className='create-partner-form'>
                <div className='form-heading-label'>
                    <h1><i className="fas fa-file-invoice" style={{ marginRight: "20px", }}></i>{t('header')} <span className='client-lbl'><i className='fas fa-user' />{t('client-title-header')}: <span className='client-lbl-value'>({userData.name})</span></span></h1>
                    <Link to={"/purchaseorders?partnerType=" + (userData.type == "Client" ? "CLIENT" : "PARTNER")}> <Button className="page-btn">{t('right-nav-btn')}  <i className='fas fa-eye'></i></Button> </Link>

                </div>
                <div className='form-body-row'>
                    <CreatePurchaseForm formik={formik} consultantsList={consultantsList} />
                </div>
                <div className='form-bottom'>
                    <Button className={"page-btn " + (Object.keys(formik.errors).length > 0 ? 'disabled-btn' : '')} disabled={Object.keys(formik.errors).length > 0} onClick={formik.handleSubmit}>Save +</Button>
                </div>
            </div>}
            <ModalAlert open={successMsg} setOpen={setSuccessMsg} msgType={"Success"} onSuccess={() => { if (userData.type == "Client") { navigate('/purchaseorders?partnerType=CLIENT') } else { navigate('/purchaseorders?partnerType=PARTNER') } }} action="Ok" color="success">
                Purchase Order created Successfully!
            </ModalAlert>

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export { CreatePurchaseOrder }